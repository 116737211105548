<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

export default {
  created() {
    return this.$store.dispatch("oauth2/authCallbackAction", { page: this });
  },
};
</script>

<template><div /></template>
