<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

import oauth2Module from "@/store/modules/oauth2";
import teamModule from "@/store/modules/team";
import teamGameModule from "@/store/modules/teamGame";
import teamPlayerModule from "@/store/modules/teamPlayer";
import teamGamePlayerModule from "@/store/modules/teamGamePlayer";

// import MENU from "cms-frontend/src/constants/menu";
import MENU from "./constants/menu";
import SITE from "./constants/site";
import CONFIG from "cms-frontend/src/constants/config";
import { EventBus } from "cms-frontend/src/plugins/event-bus";

import Header from "./components/common/header";
import Footer from "./components/common/footer";

export default {
  name: "app",
  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
  data() {
    return {
      appTitle: SITE.name,
      drawerOpen: false,
      loggingOut: false,
      initialized: false,
    };
  },
  beforeDestroy() {
    EventBus.$off(SITE.events.fetchAllGroupsAndSites);
  },
  created() {
    this.$store.registerModule("oauth2", oauth2Module);
    this.$store.registerModule("team", teamModule);
    this.$store.registerModule("teamGame", teamGameModule);
    this.$store.registerModule("teamPlayer", teamPlayerModule);    
    this.$store.registerModule("teamGamePlayer", teamGamePlayerModule);
  },
  methods: {
    logout() {
      if (confirm("Are you sure you want to logout?")) {
        this.loggingOut = true;
        return this.$clearStoreAndLogout();
      }
    },
  },
  watch: {
    $isAuthenticated(val) {
      if (val && !this.initialized) {
        this.initialized = true;
        this.$getAllGroupsAndSites();
        this.$getProfile();

        const that = this;
        // eslint-disable-next-line
        EventBus.$on(SITE.events.fetchAllGroupsAndSites, (arg) => {
          that.$getAllGroupsAndSites();
        });

        // eslint-disable-next-line
        EventBus.$on(CONFIG.events.fetchProfile, (arg) => {
          that.$getProfile();
        });
      }
    },
  },
  computed: {
    menuItems() {
      // Copy menu json so its not passed by reference
      const menu = this.$copy(MENU.user);
      if (this.$isPublisher) {
        menu.push(...MENU.publisher);
      }
      if (this.$isAdministrator) {
        menu.push(...MENU.administrator);
      }
      return menu;
    },
    styleName() {
      return this.$route.meta.styleName;
    },
    cleanedAvatar() {
      // Images with full path are saved into a Django ImageField so profile.avatar.url looks like:
      // /media/https%3A/s3.amazonaws.com/mybucket/f4b7ecaa81bd5a73a09e13ae.jpeg
      // This method cleans it up
      const avatar = this.$auth.avatar;
      if (
        avatar &&
        avatar.startsWith("/media/") &&
        avatar.includes("s3.amazonaws.com")
      ) {
        return `https://s3.amazonaws.com${avatar.split("s3.amazonaws.com")[1]}`;
      }
      return avatar;
    },
  },
};
</script>

<template>
  <v-app v-if="!loggingOut">
    <v-navigation-drawer v-if="$isAuthenticated" v-model="drawerOpen" app>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title">{{ appTitle }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>{{ item.title }}</v-list-item-content>
        </v-list-item>
        <v-list-item key="Logout" @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>Logout</v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <app-header v-if="styleName === 'raque'" v-bind:title="appTitle" />

    <div v-else-if="styleName === 'no-header-or-footer'" />

    <v-app-bar v-else app>
      <v-app-bar-nav-icon
        @click="drawerOpen = !drawerOpen"
      ></v-app-bar-nav-icon>
      <v-toolbar-title v-if="!drawerOpen">{{ appTitle }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        v-if="$isAuthenticated && !$auth.avatar"
        icon
        class="user-circle mr-2"
      >
        <v-icon color="white">mdi-account</v-icon>
      </v-btn>
      <img
        v-if="$isAuthenticated && $auth.avatar"
        :src="cleanedAvatar"
        alt="avatar"
        class="app-header-avatar mr-2"
      />
      <span
        v-if="$isAuthenticated"
        class="d-none d-sm-flex white--text- bold"
        >{{ $auth.fullName }}</span
      >
    </v-app-bar>

    <v-content>
      <v-layout>
        <router-view></router-view>
        <notifications
          group="topRightNotification"
          position="top right"
          animation-type="velocity"
        />
      </v-layout>
    </v-content>

    <app-footer v-if="styleName === 'raque'" app></app-footer>
  </v-app>
</template>

<style scoped>
.app-header-avatar {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}
.user-circle {
  border: 1px solid #777;
  background: #777;
  border-radius: 50%;
}
</style>
