/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

import '@mdi/font/css/materialdesignicons.css'
import Vue from "vue";
import App from "./App.vue";
import store from "cms-frontend/src/store";
import vuetify from "cms-frontend/src/plugins/vuetify";
import Vuetify from "vuetify";
// import router from 'cms-frontend/src/router';
import router from "./router";
import Nprogress from "nprogress";
import AuthMixin from "cms-frontend/src/components/mixins/AuthMixin";
import SiteMixin from "cms-frontend/src/components/mixins/SiteMixin";
import GlobalMixin from "cms-frontend/src/components/mixins/GlobalMixin";
import Notifications from "vue-notification";
import velocity from "velocity-animate";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";

import VueStructures from "vue-structures/src";

import "cms-frontend/src/lib/main-css";

import MySiteMixin from './components/mixins/SiteMixin';

Vue.config.productionTip = false;

Vue.mixin(AuthMixin);
Vue.mixin(SiteMixin);
Vue.mixin(MySiteMixin);
Vue.mixin(GlobalMixin);
Vue.use(Vuetify);
Vue.use(Notifications, { velocity });

Vue.use(VueStructures);

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: "mdi",
});

// Navigation guards
router.beforeEach((to, from, next) => {
  const doRouting = function() {
    if (isAuthenticated) {
      if (to.matched.some((record) => record.meta.requiresLogout)) {
        next({
          name: "sites",
        });
      } else {
        Nprogress.start();
        next();
      }
    } else {
      if (to.matched.some((record) => record.meta.requiresAuthentication)) {
        next({
          name: "login",
          query: { redirect: to.fullPath },
        });
      } else {
        Nprogress.start();
        next();
      }
    }
  };

  let isAuthenticated = store.getters["auth/getIsAuthenticated"];
  const token = localStorage.getItem("authToken");
  const doReset = store.getters["auth/getDoReset"];

  if ((token && !isAuthenticated) || doReset) {
    // This use case occurs when a user is logged in
    // thus their token (saved in localStorage) is active
    // but they may have closed their browser
    // thus their store["auth/getIsAuthenticated"] value was lost
    // We must get the data from the server again and reset isAuthenticated

    store
      .dispatch("auth/refreshUserAction")
      .then((response) => {
        if (response.data.success) {
          store
            .dispatch("auth/sessionUserAction", response.data.auth)
            // eslint-disable-next-line
            .then((response2) => {
              isAuthenticated = store.getters["auth/getIsAuthenticated"];
              doRouting();
            })
            .catch((error2) => {
              console.error("Failed to refresh user data (err: 1): ", error2);
              store.dispatch("auth/logoutUserAction");
            });
        } else {
          console.error("Failed to refresh user data (err: 2): ", response);
          store.dispatch("auth/logoutUserAction");
        }
      })
      .catch((error) => {
        console.error("Failed to refresh user data (err: 3): ", error);
        store.dispatch("auth/logoutUserAction");
      });
  } else {
    doRouting();
  }
});

// navigation guard after each
// eslint-disable-next-line
router.afterEach((to, from) => {
  Nprogress.done();
});

// eslint-disable-next-line no-new
new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
