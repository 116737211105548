import ROUTER_URLS from "cms-frontend/src/constants/urls/router";

export default {
  user: [
    {
      title: "Home",
      path: ROUTER_URLS.get("home"),
      icon: "mdi-home",
    },
    /*
    {
      title: 'Profile',
      path: ROUTER_URLS.get('profile'),
      icon: 'mdi-account',
    },
    */
  ],
  publisher: [
    {
      title: "Publisher",
      path: ROUTER_URLS.get("publisher"),
      icon: "mdi-pencil",
    },
  ],
  administrator: [
    {
      title: "Administrator",
      path: ROUTER_URLS.get("admin"),
      icon: "mdi-settings",
    },
  ],
};
