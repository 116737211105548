/*eslint no-console: ["error", { allow: ["warn", "error"] }] */
import Vue from "vue";
import SERVER_URLS from "../../constants/urls/server";
import RequestService from "cms-frontend/src/requests/RequestService";
import store from "cms-frontend/src/store";
const { get, post } = new RequestService();

/*
import Nprogress from 'nprogress';
import router from '../../router';
*/
import { INIT_DATA } from "cms-frontend/src/store/data";
import { INIT_DATA as MY_INIT_DATA } from "../data";
/*
import SITE from '../../constants/site';
import SITE from '../../constants/site';
import { EventBus } from '../../plugins/event-bus';

*/

export default {
  namespaced: true,
  state: () => {
    return {
      data: INIT_DATA.copy(MY_INIT_DATA.oauth2),
    };
  },
  getters: {
    // eslint-disable-next-line
    getUserRemoteToken: (state) => localStorage.getItem("userRemoteToken"),
  },
  actions: {
    goToAuthURIAction(context, data) {
      context.commit("goToAuthURIMutation", data);
    },
    authCallbackAction(context, { page }) {
      context.dispatch("authorizeTokenAction").then((response) => {
        if (!response.data.success) {
          return context.commit("auth/loginUserMutationFailure", response.data);
        }

        context.dispatch("getUserDataStep1Action", page).then((oauth2) => {
          context
            .dispatch("getUserDataStep2Action", oauth2)
            .then((response2) => {
              if (!response2.data.success) {
                return store.commit(
                  "auth/loginUserMutationFailure",
                  response2.data
                );
              }

              context
                .dispatch("oauth2LoginAction", response2.data.data)
                .then((response3) => {
                  if (!response3.data.success) {
                    return store.commit(
                      "auth/loginUserMutationFailure",
                      response3.data
                    );
                  }

                  store.commit("auth/sessionUserMutation", response3.data.auth);
                  store.commit("auth/loginUserMutationSuccess", {
                    data: response3.data.auth,
                    redirect: "/sites",
                  });
                });
            });
        });
      });
    },
    // eslint-disable-next-line
    oauth2LoginAction(context, params) {
      params["token"] = localStorage.getItem("remoteUserToken");
      return post(SERVER_URLS.get("oauth2_login"), params);
    },
    // eslint-disable-next-line
    getUserDataStep1Action(context, page) {
      return MY_INIT_DATA.client.code.getToken(
        `${process.env.VUE_APP_CMS_OAUTH2_REDIRECT_URI}?code=${page.$route.query.code}`
      );
    },
    // eslint-disable-next-line
    getUserDataStep2Action(context, auth) {
      const obj = auth.sign({
        method: "get",
        url: process.env.VUE_APP_CMS_OAUTH2_USER_PROFILE_URI,
      });
      return get(
        process.env.VUE_APP_CMS_OAUTH2_USER_PROFILE_URI,
        {},
        obj.headers
      );
    },
    // eslint-disable-next-line
    authorizeTokenAction(context) {
      const params = {
        token: localStorage.getItem("remoteUserToken"),
        step: "authorize",
      };
      return post(SERVER_URLS.get("oauth2_remote_user_token"), params);
    },
    logoutUserAction(context) {
      context.commit("logoutUserMutation");
    },
  },
  mutations: {
    logoutUserMutation(state) {
      localStorage.removeItem("userRemoteToken");
      Vue.set(state, "data", INIT_DATA.copy(MY_INIT_DATA.oauth2));

      window.location.reload(true);
    },
    // eslint-disable-next-line
    goToAuthURIMutation(state, data) {
      post(SERVER_URLS.get("oauth2_remote_user_token"))
        .then((response) => {
          if (response.data.success) {
            localStorage.setItem("remoteUserToken", response.data.token);
            window.location.href = MY_INIT_DATA.client.code.getUri();
          }
        })
        .catch((err) => {
          Vue.notify({
            group: "topRightNotification",
            type: "error",
            text: `Failed to login! Error: ${err.message}`,
          });
        });
    },
    // eslint-disable-next-line
    /*
    refreshToken(state, page) {
      MY_INIT_DATA.client.code
        .getToken(
          `${process.env.VUE_APP_CMS_OAUTH2_REDIRECT_URI}?code=${page.$route.query.code}`
        )
        .then(function(auth) {
          // Refresh the current users access token.
          auth.refresh().then(function(updatedUser) {
            // console.log(updatedUser !== user) //=> true
            // console.log(updatedUser.accessToken)
          });

          console.error(auth);

          const obj = auth.sign({
            method: "get",
            url: SERVER_URLS.get("get_oauth2_user_info"),
          });
        });
    },
    */
  },
};
