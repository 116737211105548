import Vue from "vue";

import VueRouter from "vue-router";

import CMS_FRONTEND_ROUTER_URLS from "cms-frontend/src/constants/urls/router";
import ROUTER_URLS from "../constants/urls/router";

// import Home from 'cms-frontend/src/components/routes/Home.vue';
// import Profile from 'cms-frontend/src/components/routes/Profile.vue';
// import Login from 'cms-frontend/src/components/routes/Login.vue';
// import ResetPassword from 'cms-frontend/src/components/routes/ResetPassword.vue';

import Home from "../components/routes/Home.vue";
import Profile from "../components/routes/Profile.vue";
// import Login from "../components/routes/Login.vue";
import ResetPassword from "../components/routes/ResetPassword.vue";
import OAuth2Callback from "../components/routes/OAuth2Callback.vue";

import Teams from "../components/teams/Teams.vue";
import TeamGames from "../components/teams/TeamGames.vue";
import TeamPlayers from "../components/teams/TeamPlayers.vue";
import TeamGamePlay from "../components/teams/TeamGamePlay.vue";

/*
import SitePages from "cms-frontend/src/components/routes/authenticated/SitePages.vue";
import Sites from "cms-frontend/src/components/routes/authenticated/Sites.vue";
import SitePageDetail from "cms-frontend/src/components/routes/authenticated/SitePageDetail.vue";
import SiteUserResponses from "cms-frontend/src/components/routes/authenticated/SiteUserResponses.vue";

import InstructorSites from "cms-frontend/src/components/routes/instructor/InstructorSites.vue";
import InstructorSitePages from "cms-frontend/src/components/routes/instructor/InstructorSitePages.vue";
import InstructorSiteUserResponses from "cms-frontend/src/components/routes/instructor/InstructorSiteUserResponses.vue";
*/

import PublisherSites from "cms-frontend/src/components/routes/publisher/PublisherSites.vue";
import PublisherSitePages from "cms-frontend/src/components/routes/publisher/PublisherSitePages.vue";
import PublisherSiteStructures from "cms-frontend/src/components/routes/publisher/PublisherSiteStructures.vue";
import PublisherSiteStructureForm from "cms-frontend/src/components/routes/publisher/PublisherSiteStructureForm.vue";
import PublisherSitePageOptions from "cms-frontend/src/components/routes/publisher/PublisherSitePageOptions.vue";
import PublisherSitePageData from "cms-frontend/src/components/routes/publisher/PublisherSitePageData.vue";
import PublisherSitePageDataContent from "cms-frontend/src/components/routes/publisher/PublisherSitePageDataContent.vue";

import AdministratorSites from "cms-frontend/src/components/routes/admin/AdministratorSites.vue";
import AdministratorSiteUsers from "cms-frontend/src/components/routes/admin/AdministratorSiteUsers.vue";

Vue.use(VueRouter);

export default new VueRouter({
  mode: "history",
  base: __dirname,
  routes: [
    // Global routes
    {
      name: "home",
      path: CMS_FRONTEND_ROUTER_URLS.get("home"),
      component: Home,
      meta: {
        styleName: "raque",
      },
    },
    {
      name: "profile",
      path: CMS_FRONTEND_ROUTER_URLS.get("profile"),
      component: Profile,
      meta: {
        requiresAuthentication: true,
      },
    },
    {
      name: "login",
      path: CMS_FRONTEND_ROUTER_URLS.get("home"),
      component: Home,
      meta: {
        requiresLogout: true,
        styleName: "raque",
      },
    },
    /*
    {
      name: "login",
      path: CMS_FRONTEND_ROUTER_URLS.get("login"),
      component: Login,
      meta: {
        requiresLogout: true,
      },
    },
    */
    {
      name: "reset_password",
      path: CMS_FRONTEND_ROUTER_URLS.get("reset_password"),
      component: ResetPassword,
      meta: {
        requiresLogout: true,
      },
    },
    {
      name: "oauth2_callback",
      path: ROUTER_URLS.get("oauth2_callback"),
      component: OAuth2Callback,
      meta: {
        action: "callback",
        requiresLogout: true,
        styleName: "no-header-or-footer",
      },
    },

    // Team routes
    {
      name: "teams",
      path: ROUTER_URLS.get("teams"),
      component: Teams,
      meta: {
        title: 'Teams',
        breadcrumbs: ['teams'],
      }
    },
    {
      name: "team_detail",
      path: ROUTER_URLS.get("team_detail"),
      component: Teams,
      meta: {
        detail: {
          getterName: 'team/getById',
          idName: 'team_id',
          nameFromKey: 'name',
        },
        breadcrumbs: [
          'teams',
          'team_detail',
        ],
      }
    },
    {
      name: "team_players",
      path: ROUTER_URLS.get("team_players"),
      component: TeamPlayers,
      meta: {
        title: 'Players',
        breadcrumbs: [
          'teams',
          'team_detail',
          'team_players',
        ],
      }
    },
    {
      name: "team_player_detail",
      path: ROUTER_URLS.get("team_player_detail"),
      component: TeamPlayers,
      meta: {
        detail: {
          getterName: 'teamPlayer/getById',
          idName: 'team_player_id',
          nameFromKey: 'name',
        },
        breadcrumbs: [
          'teams',
          'team_detail',
          'team_players',
          'team_player_detail'
        ],
      }
    },
    {
      name: "team_games",
      path: ROUTER_URLS.get("team_games"),
      component: TeamGames,
      meta: {
        title: "Games",
        breadcrumbs: [
          'teams',
          'team_detail',
          'team_games',
        ],
      }

    },
    {
      name: "team_game_detail",
      path: ROUTER_URLS.get("team_game_detail"),
      component: TeamGames,
      meta: {
        detail: {
          getterName: 'teamGame/getById',
          idName: 'team_game_id',
          nameFromKey: 'name',
        },
        breadcrumbs: [
          'teams',
          'team_detail',
          'team_games',
          'team_game_detail'
        ],
      }
    },
    {
      name: "team_game_play",
      path: ROUTER_URLS.get("team_game_play"),
      component: TeamGamePlay,
      meta: {
        title: 'Play',
        breadcrumbs: [
          'teams',
          'team_detail',
          'team_games',
          'team_game_detail',
          'team_game_play',
        ],
      }
    },

    // Authenticated routes
    /*
    {
      name: "sites",
      path: CMS_FRONTEND_ROUTER_URLS.get("sites"),
      component: Sites,
      meta: {
        requiresAuthentication: true,
        userType: "authenticated",
        title: "Sites",
        breadcrumbs: ["sites"],
      },
    },
    {
      name: "site_detail",
      path: CMS_FRONTEND_ROUTER_URLS.get("site_detail"),
      component: Sites,
      meta: {
        requiresAuthentication: true,
        userType: "authenticated",
        detail: {
          getterName: "authenticatedSite/getById",
          idName: "site_id",
        },
        breadcrumbs: ["sites", "site_detail"],
      },
    },
    {
      name: "site_page_detail",
      path: CMS_FRONTEND_ROUTER_URLS.get("site_page_detail"),
      component: SitePageDetail,
      meta: {
        requiresAuthentication: true,
        userType: "authenticated",
        detail: {
          getterName: "authenticatedSitePage/getById",
          idName: "site_page_id",
          nameFromKey: "page_name",
        },
        breadcrumbs: [
          "sites",
          "site_detail",
          "site_pages",
          "site_page_detail",
        ],
      },
    },
    {
      name: "site_pages",
      path: CMS_FRONTEND_ROUTER_URLS.get("site_pages"),
      component: SitePages,
      meta: {
        requiresAuthentication: true,
        userType: "authenticated",
        title: "Pages",
        breadcrumbs: ["sites", "site_detail", "site_pages"],
      },
    },
    {
      name: "site_page_user_responses",
      path: CMS_FRONTEND_ROUTER_URLS.get("site_page_user_responses"),
      component: SiteUserResponses,
      meta: {
        requiresAuthentication: true,
        userType: "authenticated",
        title: "Your responses",
        breadcrumbs: [
          "sites",
          "site_detail",
          "site_pages",
          "site_page_detail",
          "site_page_user_responses",
        ],
      },
    },
    */

    // Publisher routes
    {
      name: "publisher",
      path: CMS_FRONTEND_ROUTER_URLS.get("publisher"),
      component: PublisherSites,
      meta: {
        requiresAuthentication: true,
        title: "Sites",
        userType: "publisher",
        breadcrumbs: ["publisher"],
      },
    },
    {
      name: "publisher_site_detail",
      path: CMS_FRONTEND_ROUTER_URLS.get("publisher_site_detail"),
      component: PublisherSites,
      meta: {
        requiresAuthentication: true,
        detail: {
          getterName: "publisherSite/getById",
          idName: "site_id",
        },
        userType: "publisher",
        breadcrumbs: ["publisher", "publisher_site_detail"],
      },
    },
    {
      name: "publisher_site_pages",
      path: CMS_FRONTEND_ROUTER_URLS.get("publisher_site_pages"),
      component: PublisherSitePages,
      meta: {
        requiresAuthentication: true,
        userType: "publisher",
        title: "Pages",
        breadcrumbs: [
          "publisher",
          "publisher_site_detail",
          "publisher_site_pages",
        ],
      },
    },
    {
      name: "publisher_site_page_detail",
      path: CMS_FRONTEND_ROUTER_URLS.get("publisher_site_page_detail"),
      component: PublisherSitePages,
      meta: {
        requiresAuthentication: true,
        detail: {
          getterName: "publisherSitePage/getById",
          idName: "site_page_id",
          nameFromKey: "page_name",
        },
        userType: "publisher",
        breadcrumbs: [
          "publisher",
          "publisher_site_detail",
          "publisher_site_pages",
          "publisher_site_page_detail",
        ],
      },
    },
    {
      name: "publisher_site_structures",
      path: CMS_FRONTEND_ROUTER_URLS.get("publisher_site_structures"),
      component: PublisherSiteStructures,
      meta: {
        requiresAuthentication: true,
        userType: "publisher",
        title: "Structures",
        breadcrumbs: [
          "publisher",
          "publisher_site_detail",
          "publisher_site_structures",
        ],
      },
    },
    {
      name: "publisher_add_site_structure",
      path: CMS_FRONTEND_ROUTER_URLS.get("publisher_add_site_structure"),
      component: PublisherSiteStructureForm,
      meta: {
        requiresAuthentication: true,
        userType: "publisher",
        title: "Add Structure",
        breadcrumbs: [
          "publisher",
          "publisher_site_detail",
          "publisher_site_structures",
          "publisher_add_site_structure",
        ],
      },
    },
    {
      name: "publisher_edit_site_structure",
      path: CMS_FRONTEND_ROUTER_URLS.get("publisher_edit_site_structure"),
      component: PublisherSiteStructureForm,
      meta: {
        requiresAuthentication: true,
        userType: "publisher",
        detail: {
          getterName: "publisherSiteStructure/getById",
          idName: "structure_id",
        },
        breadcrumbs: [
          "publisher",
          "publisher_site_detail",
          "publisher_site_structures",
          "publisher_edit_site_structure",
        ],
      },
    },
    {
      name: "publisher_site_page_data",
      path: CMS_FRONTEND_ROUTER_URLS.get("publisher_site_page_data"),
      component: PublisherSitePageData,
      meta: {
        requiresAuthentication: true,
        userType: "publisher",
        title: "Data",
        breadcrumbs: [
          "publisher",
          "publisher_site_detail",
          "publisher_site_page_detail",
          "publisher_site_page_data",
        ],
      },
    },
    {
      name: "publisher_site_page_options",
      path: CMS_FRONTEND_ROUTER_URLS.get("publisher_site_page_options"),
      component: PublisherSitePageOptions,
      meta: {
        requiresAuthentication: true,
        userType: "publisher",
        title: "Options",
        breadcrumbs: [
          "publisher",
          "publisher_site_detail",
          "publisher_site_page_detail",
          "publisher_site_page_options",
        ],
      },
    },
    {
      name: "publisher_site_page_data_detail",
      path: CMS_FRONTEND_ROUTER_URLS.get("publisher_site_page_data_detail"),
      component: PublisherSitePageData,
      meta: {
        requiresAuthentication: true,
        userType: "publisher",
        detail: {
          getterName: "publisherSitePageData/getById",
          idName: "site_page_data_id",
          nameFromKey: "data_name",
        },
        breadcrumbs: [
          "publisher",
          "publisher_site_detail",
          "publisher_site_page_detail",
          "publisher_site_page_data",
          "publisher_site_page_data_detail",
        ],
      },
    },
    {
      name: "publisher_site_page_data_content",
      path: CMS_FRONTEND_ROUTER_URLS.get("publisher_site_page_data_content"),
      component: PublisherSitePageDataContent,
      meta: {
        requiresAuthentication: true,
        userType: "publisher",
        title: "Content",
        breadcrumbs: [
          "publisher",
          "publisher_site_detail",
          "publisher_site_page_detail",
          "publisher_site_page_data",
          "publisher_site_page_data_detail",
          "publisher_site_page_data_content",
        ],
      },
    },

    // Administrator routes

    {
      name: "admin",
      path: CMS_FRONTEND_ROUTER_URLS.get("admin"),
      component: AdministratorSites,
      meta: {
        requiresAuthentication: true,
        title: "Sites",
        userType: "administrator",
        breadcrumbs: ["admin"],
      },
    },
    {
      name: "administrator_site_detail",
      path: CMS_FRONTEND_ROUTER_URLS.get("administrator_site_detail"),
      component: AdministratorSites,
      meta: {
        requiresAuthentication: true,
        detail: {
          getterName: "administratorSite/getById",
          idName: "site_id",
        },
        userType: "administrator",
        breadcrumbs: ["admin", "administrator_site_detail"],
      },
    },
    {
      name: "administrator_site_users",
      path: CMS_FRONTEND_ROUTER_URLS.get("administrator_site_users"),
      component: AdministratorSiteUsers,
      meta: {
        requiresAuthentication: true,
        userType: "administrator",
        title: "Users",
        breadcrumbs: [
          "admin",
          "administrator_site_detail",
          "administrator_site_users",
        ],
      },
    },
    {
      name: "administrator_site_user_detail",
      path: CMS_FRONTEND_ROUTER_URLS.get("administrator_site_user_detail"),
      component: AdministratorSiteUsers,
      meta: {
        requiresAuthentication: true,
        detail: {
          getterName: "administratorSiteUser/getById",
          idName: "site_user_id",
          nameFromKey: "full_name",
        },
        userType: "administrator",
        breadcrumbs: [
          "admin",
          "administrator_site_detail",
          "administrator_site_users",
          "administrator_site_user_detail",
        ],
      },
    },
  ],
});
