<script>
export default {
  props: {
    title: String,
  },
  methods: {
    submit() {
      this.$store.dispatch("oauth2/goToAuthURIAction");
    },
  },
};
</script>

<template>
  <header class="header-area p-relative">
    <!-- Start Navbar Area -->
    <div class="navbar-area navbar-style-three">
      <div class="raque-responsive-nav">
        <div class="container">
          <div class="raque-responsive-menu">
            <div class="logo">
              <a href="/">{{ title }}</a>
            </div>
          </div>
        </div>
      </div>

      <div class="raque-nav">
        <div class="container-fluid">
          <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" href="/">{{ title }}</a>

            <div class="container collapse navbar-collapse mean-menu">
              <ul class="navbar-nav">
                <div class="dv">
                  <!--  a href="/" class="default-btn">
                    <i class="bx bx-user-circle icon-arrow before"></i>
                    <div class="label">Join For Free</div>
                  </a -->

                  <v-btn large @click="submit" block color="primary"
                    >Login via Diaspora</v-btn
                  >
                </div>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <!-- End Navbar Area -->

    <!-- Start Sticky Navbar Area -->
    <!-- div class="navbar-area navbar-style-three header-sticky">
      <div class="raque-nav">
        <div class="container-fluid">
          <nav class="navbar navbar-expand-md navbar-light">
            <a class="navbar-brand" href="/">{{ title }}</a>

            <div class="collapse navbar-collapse">
              <ul class="navbar-nav">
                <li class="nav-item">
                  <a href="/" class="default-btn">
                    <i class="bx bx-user-circle icon-arrow before"></i>
                    Join For Free
                  </a>
                  <v-btn large @click="submit" block color="primary"
                    >Login via OAuth2</v-btn
                  >
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div -->
    <!-- End Sticky Navbar Area -->
  </header>
</template>

<style scoped>
.default-btn {
  color: #fff;
}
</style>

<style scoped src="@/assets/css/bootstrap..min.css"></style>
<style scoped src="@/assets/css/boxicons.min.css"></style>
<style scoped src="@/assets/css/flaticon.css"></style>
<style scoped src="@/assets/css/meanmenu.min.css"></style>
<style scoped src="@/assets/css/animate.min.css"></style>
<style scoped src="@/assets/css/owl.carousel.min.css"></style>
<style scoped src="@/assets/css/odometer.min.css"></style>
<style scoped src="@/assets/css/nice-select.min.css"></style>
<style scoped src="@/assets/css/viewer.min.css"></style>
<style scoped src="@/assets/css/slick.min.css"></style>
<style scoped src="@/assets/css/magnific-popup.min.css"></style>
<style scoped src="@/assets/css/style.css"></style>
<style scoped src="@/assets/css/responsive.css"></style>
