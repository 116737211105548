const ClientOAuth2 = require("client-oauth2");

export const INIT_DATA = {
  oauth2: {
    remoteUserToken: "",
  },
  client: new ClientOAuth2({
    clientId: process.env.VUE_APP_CMS_OAUTH2_CLIENT_ID,
    clientSecret: process.env.VUE_APP_CMS_OAUTH2_CLIENT_SECRET,
    accessTokenUri: process.env.VUE_APP_CMS_OAUTH2_ACCESS_TOKEN_URI,
    authorizationUri: process.env.VUE_APP_CMS_OAUTH2_AUTHORIZATION_URI,
    // This is local router - oauth2_callback - must match app entry
    redirectUri: process.env.VUE_APP_CMS_OAUTH2_REDIRECT_URI,
    scopes: ["read"],
  }),
  team: {
    objects: {
      // Use 0 to show object example
      0: {
        id: 0, // team_id
        name: '',
        description: '',
        photo_url: '',
        confirm_on_changes: true,
        timestamp: 0,
      },
    },
    list: [],
  },
  teamPlayer: {
    objects: {
      // Use 0 to show object example
      0: {
        id: 0, // team_player_id
        name: '',
        notes: '',
        min_time_per_rest: '', // Minimum minutes per rest after a stint
        max_time_per_stint: '', // Maximum minutes per stint
        photo_url: '',
        timestamp: 0,
      },
    },
    teamObjectList: {
      0: [],
    },
    teamObjectIdList: {
      0: [],
    },
  },
  teamGame: {
    objects: {
      // Use 0 to show object example
      0: {
        id: 0, // team_game_id
        name: '',
        type: '',
        notes: '',
        number_of_players: 0,
        time_per_period: 0, // Number of minutes per period
        min_time_per_rest: '', // Minimum minutes per rest after a stint
        max_time_per_stint: '', // Maximum minutes per stint
        current_period: 1,
        game_started: false,
        game_over: false,
        time_segments: { // list of time segments per period
          1: [
            {
              period: 0,
              start_timestamp: 0,
              stop_timestamp: 0,
            },
          ],
        },
        timestamp: 0,
      },
    },
    teamObjectList: {
      0: [],
    },
    teamObjectIdList: {
      0: [],
    },
  },
  teamGamePlayer: {
    objects: {
      // Use 0 to show object example
      0: {
        id: 0, // team_game_player_id
        team_game_id: 0,
        team_player_id: 0,
        min_time_per_rest: '', // Copied from teamPlayer or teamGame
        max_time_per_stint: '', // Copied from teamPlayer or teamGame
        in_game: false,
        notes: '',
        time_segments: { // data per current_period
          1: {
            in_game: [ // list of periods
              {
                period: 0,
                start_timestamp: 0,
                stop_timestamp: 0,
              },
            ],
            out_of_game: [ // list of periods
              {
                period: 0,
                start_timestamp: 0,
                stop_timestamp: 0,
              },
            ],  
          },
        },
        timestamp: 0,
      },
    },
    teamGameObjectList: {
      0: [],
    },
    teamGameObjectIdList: {
      0: [],
    },
  },
};
