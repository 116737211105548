export default {
  // eslint-disable-next-line
  get: function(name, opt) {
    switch (name) {
      case "oauth2_login":
        return "/oauth2/login/";
      case "oauth2_remote_user_token":
        return "/oauth2/remote-user-token/";
    }
  },
};
