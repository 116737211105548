/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

export default {
  // eslint-disable-next-line
  get: function(name, opt) {
    switch (name) {
      // Global routers
      /*
      case "oauth2_login":
        return '/oauth2/login/';
      */
      case "oauth2_callback":
        return "/oauth2/callback/";
      case "teams":
        return "/teams/";
      case "team_detail":
        if (opt) {
          return `/teams/${opt.team_id}/`;
        } else {
          return '/teams/:team_id/';
        }
      case "team_players":
        if (opt) {
          return `/teams/${opt.team_id}/players/`;
        } else {
          return '/teams/:team_id/players/';
        }
      case "team_player_detail":
        if (opt) {
          return `/teams/${opt.team_id}/games/${opt.team_player_id}/`;
        } else {
          return '/teams/:team_id/players/:team_player_id/';
        }
      case "team_games":
        if (opt) {
          return `/teams/${opt.team_id}/games/`;
        } else {
          return '/teams/:team_id/games/';
        }
      case "team_game_detail":
        if (opt) {
          return `/teams/${opt.team_id}/games/${opt.team_game_id}/`;
        } else {
          return '/teams/:team_id/games/:team_game_id/';
        }
      case "team_game_play":
        if (opt) {
          return `/teams/${opt.team_id}/games/${opt.team_game_id}/play/`;
        } else {
          return '/teams/:team_id/games/:team_game_id/play/';
        }
    }
  },
};
