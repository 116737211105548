<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

import { EventBus } from '../../../plugins/event-bus';
import TEAM from '../../../constants/team';

export default {
  name: 'UploadDownloadDeleteDataForm',
  props: ['dialog'],
  data() {
    return {
      valid: false,
      action: '',
      uploadFile: null,
      process: {
        team: false,
        teamPlayer: false,
        teamGame: false,
      },
      disabled: {
        team: false,
        teamPlayer: false,
        teamGame: false,
      },
      messages: {
        action: [],
        uploadFile: [],
        process: {
          team: [],
          teamPlayer: [],
          teamGame: [],
        },
      },
      actions: [
        {
          value: 'upload',
          text: 'Upload'
        },
        {
          value: 'download',
          text: 'Download',
        },
        {
          value: 'delete',
          text: 'Delete'
        }
      ],
      actorLabel: {
        team: "team",
        teamGame: "game",
        teamPlayer: "player",
      }
    };
  },
  computed: {
    actionLabel() {
      return this.action ? this.actions.filter(item => item.value === this.action)[0].text : '';
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.closeDialog();
      }
    },
  },
  methods: {
    validate() {
      this.clearMessages();
      this.clearDisabledFields();
      this.valid = true;

      if (this.action === 'upload') {
        if (!this.uploadFile) {
          this.messages.uploadFile = 'Select a file';
          this.valid = false;
        }
      } else {
        if (!Object.keys(this.process).filter(val => this.process[val]).length) {
          this.messages.process.team = `Check this box if you want to ${this.actionLabel.toLowerCase()} 'Teams' data.`
          this.messages.process.teamPlayer = `Check this box if you want to ${this.actionLabel.toLowerCase()} 'Players' data.`
          this.messages.process.teamGame = `Check this box if you want to ${this.actionLabel.toLowerCase()} 'Players' data.`
          this.valid = false;
        }

        if (this.action === 'delete') {
          if (this.process.teamPlayer) {
            this.process.teamGame = true;
            this.disabled.teamGame = true;
          }

          if (this.process.team) {
            this.process.teamGame = true;
            this.process.teamPlayer = true;
            this.disabled.teamGame = true;
            this.disabled.teamPlayer = true;
          }
        } else if (this.action === 'download') {
          if (this.process.teamPlayer) {
            this.process.team = true;
            this.disabled.team = true;
          }

          if (this.process.teamGame) {
            this.process.team = true;
            this.process.teamPlayer = true;
            this.disabled.team = true;
            this.disabled.teamPlayer = true;
          }
        } else {
          this.messages.action = 'Select an action';
          this.valid = false;
        }
      }

    },
    clearDisabledFields() {
      this.disabled.team = false;
      this.disabled.teamPlayer = false;
      this.disabled.teamGame = false;
    },
    clearForm() {
      this.clearMessages();
      this.clearDisabledFields();
      this.action = '',
      this.uploadFile = null,
      this.process = {
        team: false,
        teamPlayer: false,
        teamGame: false,
      };
    },
    clearMessages() {
      this.messages.action = '';
      this.messages.uploadFile = '';
      this.messages.process = {
        team: '',
        teamPlayer: '',
        teamGame: '',
      };
    },
    submit() {
      this.validate();

      if (!this.valid) return;

      if (['upload', 'delete'].indexOf(this.action) > -1) {
        if (this.action === 'upload') {
          if (!confirm('The uploaded data may overwrite current team, player and game data. Are you sure?')) {
            return;
          }
        } else {
          if (!confirm(`All current ${Object.keys(this.process).filter(val => this.process[val]).map(val2 => this.actorLabel[val2]).join(', ')} data will be deleted. Are you sure?`)) {
            return;
          }
        }
      }

      if (this.action === 'download') {
        const data = {
          ...(this.process.team ? {team: JSON.parse(localStorage.getItem('team'))}: {}),
          ...(this.process.teamPlayer ? {teamPlayer: JSON.parse(localStorage.getItem('teamPlayer'))}: {}),
          ...(this.process.teamGame ? {teamGame: JSON.parse(localStorage.getItem('teamGame'))}: {}),
          ...(this.process.teamGame ? {teamGamePlayer: JSON.parse(localStorage.getItem('teamGamePlayer'))}: {}),
        };
        const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));

        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download",  "download-team-data.json");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();

      } else if (this.action === 'delete') {
        if (this.process.team) {
          localStorage.removeItem('team');
        }
        if (this.process.teamPlayer) {
          localStorage.removeItem('teamPlayer');          
        }
        if (this.process.teamGame) {
          localStorage.removeItem('teamGame');
          localStorage.removeItem('teamGamePlayer');
        }

        this.closeDialogAndReload();
      } else if (this.action === 'upload') {
        // Credit: https://stackoverflow.com/a/754398/52160
        let reader = new FileReader();
        reader.readAsText(this.uploadFile, "UTF-8");
        reader.onload =  evt => {
          const uploadedText = evt.target.result;
          const uploadedData = JSON.parse(uploadedText);

          let hasData = false;

          if (uploadedData.team) {
            const team = {
              ...JSON.parse(localStorage.getItem('team', '{}')),
              ...uploadedData.team,
            };
            localStorage.setItem('team', JSON.stringify(team));
            hasData = true;
          }
          if (uploadedData.teamPlayer) {
            const teamPlayer = {
              ...JSON.parse(localStorage.getItem('teamPlayer', '{}')),
              ...uploadedData.teamPlayer,
            };
            localStorage.setItem('teamPlayer', JSON.stringify(teamPlayer));
            hasData = true;
          }
          if (uploadedData.teamGame) {
            const teamGame = {
              ...JSON.parse(localStorage.getItem('teamGame', '{}')),
              ...uploadedData.teamGame,
            };
            localStorage.setItem('teamGame', JSON.stringify(teamGame));
            hasData = true;
          }
          if (uploadedData.teamGamePlayer) {
            const teamGamePlayer = {
              ...JSON.parse(localStorage.getItem('teamGamePlayer', '{}')),
              ...uploadedData.teamGamePlayer,
            };
            localStorage.setItem('teamGamePlayer', JSON.stringify(teamGamePlayer));
            hasData = true;
          }

          if (hasData) {
            this.closeDialogAndReload();
          }


        }
        reader.onerror = evt => {
          console.error('Error: ', evt);
        }
      }
    },
    closeDialog() {
      this.clearForm();
      EventBus.$emit(TEAM.events.closeUploadDownloadDeleteDataDialog);
    },
    closeDialogAndReload() {
      this.clearForm();
      EventBus.$emit(TEAM.events.closeUploadDownloadDeleteDataAndReloadDialog);
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title class="headline"
        >Upload / Download / Delete Data</v-card-title
      >

      <v-form @keyup.enter.native="submit" v-model="valid" class="m-20">
        <v-select
          :items="actions"
          label="Action"
          v-model="action"
          required
          :error-messages="messages.action"
          @change="validate()"
        ></v-select>

        <v-checkbox
          v-if="action !== '' && action !== 'upload'"
          v-model="process.team"
          :disabled="disabled.team"
          label="Teams"
          :error-messages="messages.process.team"
          @change="validate()"
        ></v-checkbox>

        <v-checkbox
          v-if="action !== '' && action !== 'upload'"
          v-model="process.teamPlayer"
          :disabled="disabled.teamPlayer"
          label="Players"
          :error-messages="messages.process.teamPlayer"
          @change="validate()"
        ></v-checkbox>

        <v-checkbox
          v-if="action !== '' && action !== 'upload'"
          v-model="process.teamGame"
          :disabled="disabled.teamGame"
          label="Games"
          :error-messages="messages.process.teamGame"
          @change="validate()"
        ></v-checkbox>

        <v-file-input
          v-if="action === 'upload'"
          v-model="uploadFile"
          accept="application/json"
          label="Team File"
          placeholder="File name ends with .json"
          :error-messages="messages.uploadFile"
          @change="validate()"
        ></v-file-input>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="white--text" color="grey darken-1" @click="closeDialog()"
          >Close</v-btn
        >
        <v-btn
          color="primary darken-1"
          @click="submit"
          >{{ actionLabel ? actionLabel : 'Submit' }}</v-btn
        >
        <!-- v-btn
          v-if="!isEditForm"
          color="primary darken-1"
          @click="submit"
          >Add team</v-btn
        -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>