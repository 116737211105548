<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

import { EventBus } from '../../../plugins/event-bus';
import TEAM from '../../../constants/team';

export default {
  name: 'TeamForm',
  props: ['teamId', 'dialog'],
  data() {
    return {
      valid: false,
      // order: 0,
      id: 0,
      name: '',
      description: '',
      confirmOnChanges: true,
      minimumRestTime: '',
      submitBtnIsDisabled: false,
      messages: {
        name: [],
        description: [],
      },
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        if (this.teamId) {
          const team = this.$store.getters[
            'team/getById'
          ](this.teamId);
          this.name = team.name;
          // this.order = team.order;
          this.description = team.description;
          this.confirmOnChanges = team.confirm_on_changes;
        }
      } else {
        this.closeDialog();
      }
    },
  },
  mounted() {},
  computed: {
    isEditForm() {
      return this.teamId;
    },
  },
  methods: {
    validate() {
      this.clearMessages();
      this.valid = true;
      if (!this.name) {
        this.messages.name = 'Team name is required';
        this.valid = false;
      }
    },
    clearForm() {
      this.clearMessages();
      this.name = '';
      this.description = '';
      this.confirmOnChanges = true;
      this.id = 0;
    },
    clearMessages() {
      this.messages.name = '';
      this.messages.minimum_rest_time = '';
    },
    submit() {
      this.validate();
      if (!this.valid) return;

      const payload = {
        id: this.teamId ? this.teamId : this.$generateUUID(),
        name: this.name,
        description: this.description,
        confirm_on_changes: this.confirmOnChanges,
        timestamp: new Date().getTime(),
      };

      let method;
      if (this.isEditForm) {
        method = 'put';
      } else {
        method = 'post';
      }

      this.$store.dispatch(
        'team/submitTeamAction',
        {
          method: method,
          payload: payload,
          page: this,
        },
      );
    },
    closeDialog() {
      this.clearForm();
      EventBus.$emit(TEAM.events.closeTeamDialog);
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title v-if="isEditForm" class="headline"
        >Edit team</v-card-title
      >
      <v-card-title v-if="!isEditForm" class="headline"
        >Add team</v-card-title
      >

      <v-form @keyup.enter.native="submit" v-model="valid" class="m-20">
        <v-text-field
          label="Name"
          v-model="name"
          required
          :error-messages="messages.name"
          @keyup="validate()"
        ></v-text-field>
        <v-text-field
          label="Description"
          v-model="description"
        ></v-text-field>
        <v-switch
          v-model="confirmOnChanges"
          :label="`[${confirmOnChanges ? 'On' : 'Off'}] Ask for confirmation before substitutions (guards against unintended substitutions due to sensitive touches on mobile phones)`"
        ></v-switch>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="white--text" color="grey darken-1" @click="closeDialog()"
          >Close</v-btn
        >
        <v-btn
          v-if="isEditForm"
          color="primary darken-1"
          @click="submit"
          >Edit team</v-btn
        >
        <v-btn
          v-if="!isEditForm"
          color="primary darken-1"
          @click="submit"
          >Add team</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>