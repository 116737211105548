<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

import { EventBus } from '../../../plugins/event-bus';
import TEAM from '../../../constants/team';

/*

// S3 stuff

        if (
          this.fileListSelectedForS3.length ||
          this.fileListRecordedForS3.length
        ) {
          if (this.$isS3Setup()) {
            return this.doS3Upload(payload);
          } else {
            if (this.fileListRecordedForS3.length) {
              this.fileListForServer = this.fileListRecordedForS3;
            } else if (this.fileListSelectedForS3.length) {
              this.fileListForServer = this.fileListSelectedForS3;
            }
          }
        }

/// -

    selectMedia: function(file) {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener('load', () => {
        this.fileListSelectedForS3 = [];
        this.fileListRecordedForS3 = [];
        this.audioRecordedFile = null;
        this.audioSelectedFile = null;
        this.videoSelectedFile = null;
        this.videoRecordedFile = null;
        this.mediaFilename = '';
        this.imageUrl = '';
        this.imageFile = null;
        const media = {};

        if (file.type.startsWith('image')) {
          this.mediaFilename = file.name;
          this.imageUrl = fr.result;
          this.imageFile = file;

          media.name = this.mediaFilename;
          media.fieldName = 'media_image';
          media.url = this.imageUrl;
          media.file = this.imageFile;
        } else if (file.type.startsWith('audio')) {
          this.mediaFilename = file.name;
          this.audioSelectedFile = fr.result;

          media.name = this.mediaFilename;
          media.fieldName = 'media_audio';
          media.url = fr.result;
          media.file = file;
        } else if (file.type.startsWith('video')) {
          this.mediaFilename = file.name;
          this.videoSelectedFile = fr.result;

          media.name = this.mediaFilename;
          media.fieldName = 'media_video';
          media.url = fr.result;
          media.file = file;
        }
        this.fileListSelectedForS3.push(media);
      });
    },

    /// - 

    doS3Upload(payload) {
      // Recordings take precedence
      if (this.fileListRecordedForS3.length) {
        this.fileListForS3 = this.fileListRecordedForS3;

        this.googleTagParams['source'] = 'recorded';
      } else if (this.fileListSelectedForS3.length) {
        this.fileListForS3 = this.fileListSelectedForS3;

        this.googleTagParams['source'] = 'selected';
      }

      let fieldName = '';
      let contentType = '';
      const f = this.fileListForS3[0];

      const store = this.$store;

      if (f.contentType) {
        contentType = f.contentType;
      } else if (f.file.type) {
        contentType = f.file.type;
      }

      if (contentType.startsWith('image')) {
        fieldName = 'media_image_s3';
      } else if (contentType.startsWith('audio')) {
        fieldName = 'media_audio_s3';
      } else if (contentType.startsWith('video')) {
        fieldName = 'media_video_s3';
      } else {
        store.dispatch('messaging/submitPostActionFailure', {
          error: this.$t('message.couldNotPostMediaHasNoContentType'),
        });
        this.submitIsDisabled = false;
        return;
      }

      const fileName = this.$getFileName(contentType);
      const filePath = `message/${this.$auth.user.guid}/${fileName}`;

      const upload = new AWS.S3.ManagedUpload({
        params: {
          Bucket: this.$auth.meta.s3_bucket,
          Key: filePath,
          Body: f.file,
          ACL: 'private',
          ContentType: contentType,
        },
      });

      const that = this;

      // Do not send file name to Google so get a
      // substring of file name to protect privacy
      that.googleTagParams['s3-file'] = fileName.substring(0, 10);
      that.googleTagParams['type'] = contentType.replace('/', '-');

      const promise = upload.promise();
      promise.then(
        // eslint-disable-next-line
        function(data) {
          payload[`${fieldName}__name`] = f.file.name;
          payload[`${fieldName}__size`] = f.file.size;
          payload[`${fieldName}__type`] = f.file.type;
          payload[`${fieldName}__s3_bucket`] =
            that.$auth.meta.s3_bucket;
          payload[`${fieldName}__s3_key`] = filePath;
          payload[`${fieldName}__upload_success`] = true;
          payload[`${fieldName}__upload_error_message`] = '';

          that.googleTagParams['s3-success'] = true;

          that.doSubmit(payload);
        },
        function(err) {
          payload[`${fieldName}__upload_success`] = false;
          payload[`${fieldName}__upload_error_message`] = err.message;

          that.googleTagParams['s3-success'] = false;
          that.googleTagParams['send-to-server'] = true;

          // Try server upload
          that.fileListForServer = that.fileListForS3;
          that.doSubmit(payload);
        },
      );
    },

    //// --


    $getS3Url(url) {
      if (
        url.toLowerCase().startsWith('http://') ||
        url.toLowerCase().startsWith('https://')
      ) {
        return url;
      }
      return `https://${this.$auth.meta.s3_prefix}/${url}`;
    },
    $isS3Setup() {
      if (this.$auth.meta.s3_token && this.$auth.meta.s3_region) {
        return true;
      }
      return false;
    },


//// - 

import AWS from 'aws-sdk';

/// -

  created() {
    if (this.$isS3Setup()) {
      AWS.config.region = this.$auth.meta.s3_region;
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: this.$auth.meta.s3_token,
      });
    }
  },

/// - 

*/

export default {
  name: 'TeamPlayerForm',
  props: ['teamId', 'teamPlayerId', 'dialog'],
  data() {
    return {
      valid: false,
      // order: 0,
      id: 0,
      name: '',
      notes: '',
      photoUrl: '',
      minTimePerRest: '',
      maxTimePerStint: '',
      submitBtnIsDisabled: false,
      messages: {
        name: '',
        notes: '',
        minTimePerRest: '',
        maxTimePerStint: '',
      },
      TEAM: TEAM,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        if (this.teamPlayerId) {
          const player = this.$store.getters[
            'teamPlayer/getById'
          ](this.teamPlayerId);
          this.name = player.name;
          this.notes = player.notes;
          this.photoUrl = player.photo_url;
          this.minTimePerRest = player.min_time_per_rest;
          this.maxTimePerStint = player.max_time_per_stint;
        }
      } else {
        this.closeDialog();
      }
    },
  },
  mounted() {},
  computed: {
    isEditForm() {
      return this.teamPlayerId;
    },
  },
  methods: {
    validate() {
      this.clearMessages();
      this.valid = true;
      if (!this.name) {
        this.messages.name = 'Player name is required';
        this.valid = false;
      }
      if (this.maxTimePerStint && this.maxTimePerStint < 1) {
        this.messages.maxTimePerStint = 'This should be more than 0';
        this.valid = false;
      }
      if (this.minTimePerRest && this.minTimePerRest < 1) {
        this.messages.minTimePerRest = 'This should be more than 0';
        this.valid = false;
      }
    },
    clearForm() {
      this.clearMessages();
      this.name = '';
      this.notes = '';
      this.photoUrl = '';
      this.minTimePerRest = '';
      this.maxTimePerStint = '';
      // this.order = 0;
      this.id = 0;
    },
    clearMessages() {
      this.messages.name = '';
      this.messages.notes = '';
      this.messages.minTimePerRest = '';
      this.messages.maxTimePerStint = '';
    },
    submit() {
      this.validate();
      if (!this.valid) return;

      const payload = {
        id: this.teamPlayerId ? this.teamPlayerId : this.$generateUUID(),
        team_id: this.teamId,
        name: this.name,
        notes: this.notes,
        photo_url: this.photoUrl,
        max_time_per_stint: this.maxTimePerStint,
        min_time_per_rest: this.minTimePerRest,
        timestamp: new Date().getTime(),
      };

      let method;
      if (this.isEditForm) {
        method = 'put';
      } else {
        method = 'post';
      }

      this.$store.dispatch(
        'teamPlayer/submitTeamPlayerAction',
        {
          method: method,
          payload: payload,
          page: this,
        },
      );
    },
    closeDialog() {
      this.clearForm();
      EventBus.$emit(TEAM.events.closeTeamPlayerDialog);
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title v-if="isEditForm" class="headline"
        >Edit player</v-card-title
      >
      <v-card-title v-if="!isEditForm" class="headline"
        >Add player</v-card-title
      >

      <v-card-subtitle class="mt-5" v-html="TEAM.colorCode" />

      <v-form @keyup.enter.native="submit" v-model="valid" class="m-20">
        <v-text-field
          label="Name"
          v-model="name"
          required
          :error-messages="messages.name"
          @keyup="validate()"
        ></v-text-field>
        <v-text-field
          label="Photo URL"
          v-model="photoUrl"
        ></v-text-field>
        <!-- TODO - add positions and time allotment -->
        <v-text-field
          label="Notes"
          v-model="notes"
          placeholder="Enter player notes"
        ></v-text-field>
        <v-text-field
          label="Maximum time per stint (in minutes)"
          type="number"
          v-model="maxTimePerStint"
          placeholder="Maximum minutes player should play per stint"
          :error-messages="messages.maxTimePerStint"
          @keyup="validate()"
        ></v-text-field>        
        <v-text-field
          label="Minimum time per rest (in minutes)"
          type="number"
          v-model="minTimePerRest"
          placeholder="Minimum minutes player should rest after play"
          :error-messages="messages.minTimePerRest"
          @keyup="validate()"
        ></v-text-field>        

        <!-- needed if only one field in form for keyup.enter to work -->
        <v-text-field style="display:none" />
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="white--text" color="grey darken-1" @click="closeDialog()"
          >Close</v-btn
        >
        <v-btn
          v-if="isEditForm"
          color="primary darken-1"
          @click="submit"
          >Edit player</v-btn
        >
        <v-btn
          v-if="!isEditForm"
          color="primary darken-1"
          @click="submit"
          >Add player</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>