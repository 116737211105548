/*eslint no-console: ["error", { allow: ["warn", "error"] }] */
import Vue from 'vue';
import { INIT_DATA } from "cms-frontend/src/store/data";
import { INIT_DATA as MY_INIT_DATA } from "../data";
import Nprogress from 'nprogress';

import { saveState, syncState } from '../../helpers/store';

export default {
  namespaced: true,
  state: {
    data: INIT_DATA.copy(MY_INIT_DATA.teamGame)
  },
  getters: {
    getById: (state) => id => { 
      syncState("teamGame", state);
      return id ? state.data.objects[id] : {};
    },
    getByTeamId: (state) => teamId => { 
      syncState("teamGame", state);
      return teamId ? state.data.teamObjectList[teamId] : [];
    },
  },
  actions: {
    add(context, data) {
      context.commit('add', data);
    },
    addList(context, dataList) {
      dataList.forEach(data => {
        context.commit('add', data);
      });
    },
    deleteByTeamIdAction(context, teamId) {
      context.commit('deleteByTeamIdMutation', teamId);
    },
    // eslint-disable-next-line
    submitTeamGameAction(context, { method, payload, page }) {
      if (page) {
        Nprogress.start();
      }
      // return post(ServerUrls.get('feedback'), payload);
      context.commit("submitTeamGameMutationSuccess", {data: payload, page: page});
    },
    logoutUserAction(context) {
      context.commit("logoutUserMutation");
    },
  },
  mutations: {
    add(state, data) {
      Vue.set(state.data.objects, data.id, data);

      let objectArray = [];
      let objectIdArray = [];

      if (data.team_id in state.data.teamObjectIdList) {
        objectArray = state.data.teamObjectList[data.team_id];
        objectIdArray = state.data.teamObjectIdList[data.team_id];
      }

      const idx = objectIdArray.indexOf(data.id);
      if (idx < 0) {
        // Add
        objectIdArray.push(data.id);
      } else {
        // Update
        objectIdArray.splice(idx, 1, data.id);
      }

      let idx2 = -1;
      for (let i = 0; i < objectArray.length; i++) {
        if (objectArray[i].id === data.id) {
          idx2 = i;
          break;
        }
      }
      if (idx2 < 0) {
        // Add
        objectArray.push(data);
      } else {
        // Update
        objectArray.splice(idx2, 1, data);
      }

      Vue.set(state.data.teamObjectIdList, data.team_id, objectIdArray);
      Vue.set(state.data.teamObjectList, data.team_id, objectArray);
    },

    deleteByTeamIdMutation(state, teamId) {
      const objects = JSON.parse(JSON.stringify(state.data.objects));
      const teamObjectList = JSON.parse(JSON.stringify(state.data.teamObjectList));
      const teamObjectIdList = JSON.parse(JSON.stringify(state.data.teamObjectIdList));

      state.data.teamObjectIdList[teamId].forEach(id => {
        delete objects[id];
      });

      delete teamObjectList[teamId];
      delete teamObjectIdList[teamId];
      Vue.set(state.data, "objects", objects);

      Vue.set(state.data.teamObjectIdList, teamId, teamObjectIdList);
      Vue.set(state.data.teamObjectList, teamId, teamObjectList);

    },
    submitTeamGameMutationSuccess(state, { data, page }) {

      const stateData = INIT_DATA.copy(state.data);
      stateData.objects[data.id] = data;

      let objectArray = [];
      let objectIdArray = [];

      if (data.team_id in stateData.teamObjectIdList) {
        objectArray = stateData.teamObjectList[data.team_id];
        objectIdArray = stateData.teamObjectIdList[data.team_id];
      }

      const idx = objectIdArray.indexOf(data.id);
      if (idx < 0) {
        // Add
        objectIdArray.push(data.id);
      } else {
        // Update
        objectIdArray.splice(idx, 1, data.id);
      }

      let idx2 = -1;
      for (let i = 0; i < objectArray.length; i++) {
        if (objectArray[i].id === data.id) {
          idx2 = i;
          break;
        }
      }
      if (idx2 < 0) {
        // Add
        objectArray.push(data);
      } else {
        // Update
        objectArray.splice(idx2, 1, data);
      }

      stateData.teamObjectIdList[data.team_id] = objectIdArray;
      stateData.teamObjectList[data.team_id] = objectArray;

      saveState('teamGame', state, stateData);

      if (page) {
        Nprogress.done();
        Vue.notify({
          group: 'topCenterNotification',
          type: 'success',
          text: 'Team game added',
        });
  
        page.closeDialog();  
      }
    },
    // eslint-disable-next-line
    submitTeamGameMutationFailure(state, error) {
      Nprogress.done();
      Vue.notify({
        group: 'topCenterNotification',
        type: 'error',
        text: 'Team game failed to add'
      });
    },
    logoutUserMutation(state) {
      Vue.set(state, "data", INIT_DATA.copy(MY_INIT_DATA.teamGame));
    },
  },
};