/**
 * Helpers Functions
 */

/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

import Vue from 'vue';
import { INIT_DATA } from '../store/data'

/**
 * Function get state object from localStorage
 */
export function getStateFromLocalstorage(name) {
  try {
    const data = localStorage.getItem(name);
    if (data === null) {
      return INIT_DATA[name];
    } else {
      return JSON.parse(data);
    }
  } catch (err) {
    return INIT_DATA[name];
  }
}

/**
 * Function to set state object and save to local storage
 * 
 * TODO: Now that we are getting state from localstorage
 * syncState may no longer be needed
 */
export function saveState(name, state, data) {
  if (data === null){ 
    console.warn("WARNING: Aborting null save to state");
  }
  data.savedTimestamp = new Date().getTime();
  const storageData = getStateFromLocalstorage(name);
  const finalData = {
    ...(storageData ? storageData : {}),
    ...data,
  }
  Vue.set(state, "data", finalData);
  localStorage.setItem(name, JSON.stringify(finalData));
}

/**
 * Function get sync state object with localStorage
 * 
 * TODO: Now that we are getting state from localstorage in saveState
 * syncState may no longer be needed
 */
export function syncState(name, state) {
  try {
    const data = getStateFromLocalstorage(name);
    if (!data.savedTimestamp || (data.savedTimestamp != state.data.savedTimestamp)) {
      // Resave the state if its in localstorage but not in
      // the state (usually happens after site refreshes)
      saveState(name, state, data);
    }
  } catch (err) {
    console.error(err);
  }
}