<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */


export default {
  computed: {
    $isMobile() {
      return ['lg', 'xl', 'md'].indexOf(this.$vuetify.breakpoint.name) < 0;
    },
  },
  methods: {
    $generateUUID() {
      // https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
      let d = new Date().getTime();
      let d2 =
        (performance &&
          performance.now &&
          performance.now() * 1000) ||
        0;
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
        /[xy]/g,
        function(c) {
          var r = Math.random() * 16;
          if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
          } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
          }
          return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
        },
      );
    },
    $logout() {
      if (confirm(this.$t('message.doYouWantToLogout'))) {
        this.$store.dispatch('locale/logoutUserAction');
        this.$store.dispatch('messaging/logoutUserAction');
        this.$store.dispatch('site/logoutUserAction');

        // This should be last
        this.$store.dispatch('auth/logoutUserAction', this.$router);
      }
    },
  },
};
</script>
