/*eslint no-console: ["error", { allow: ["warn", "error"] }] */
import Vue from 'vue';
import { INIT_DATA } from "cms-frontend/src/store/data";
import { INIT_DATA as MY_INIT_DATA } from "../data";
import Nprogress from 'nprogress';

import { saveState, syncState } from '../../helpers/store';

export default {
  namespaced: true,
  state: {
    data: INIT_DATA.copy(MY_INIT_DATA.teamGamePlayer)
  },
  getters: {
    getById: (state) => id => { 
      syncState("teamGamePlayer", state);
      return id ? state.data.objects[id] : {};
    },
    getByTeamGameId: (state) => teamGameId => { 
      syncState("teamGamePlayer", state);
      return teamGameId ? state.data.teamGameObjectList[teamGameId] : [];
    },
  },
  actions: {
    add(context, data) {
      context.commit('add', data);
    },
    addList(context, dataList) {
      dataList.forEach(data => {
        context.commit('add', data);
      });
    },
    deleteByTeamGameIdAction(context, teamGameId) {
      context.commit('deleteByTeamGameIdMutation', teamGameId);
    },
    // eslint-disable-next-line
    submitTeamGamePlayerAction(context, { method, payload, page }) {
      Nprogress.start();
      // return post(ServerUrls.get('feedback'), payload);
      context.commit("submitTeamGamePlayerMutationSuccess", {data: payload, page: page});
    },
    logoutUserAction(context) {
      context.commit("logoutUserMutation");
    },
  },
  mutations: {
    add(state, data) {
      Vue.set(state.data.objects, data.id, data);

      let objectArray = [];
      let objectIdArray = [];

      if (data.team_game_id in state.data.teamGameObjectIdList) {
        objectArray = state.data.teamGameObjectList[data.team_game_id];
        objectIdArray = state.data.teamGameObjectIdList[data.team_game_id];
      }

      const idx = objectIdArray.indexOf(data.id);
      if (idx < 0) {
        // Add
        objectIdArray.push(data.id);
      } else {
        // Update
        objectIdArray.splice(idx, 1, data.id);
      }

      let idx2 = -1;
      for (let i = 0; i < objectArray.length; i++) {
        if (objectArray[i].id === data.id) {
          idx2 = i;
          break;
        }
      }
      if (idx2 < 0) {
        // Add
        objectArray.push(data);
      } else {
        // Update
        objectArray.splice(idx2, 1, data);
      }

      Vue.set(state.data.teamGameObjectIdList, data.team_game_id, objectIdArray);
      Vue.set(state.data.teamGameObjectList, data.team_game_id, objectArray);
    },

    deleteByTeamGameIdMutation(state, teamGameId) {
      const objects = JSON.parse(JSON.stringify(state.data.objects));
      const teamGameObjectList = JSON.parse(JSON.stringify(state.data.teamGameObjectList));
      const teamGameObjectIdList = JSON.parse(JSON.stringify(state.data.teamGameObjectIdList));

      state.data.teamGameObjectIdList[teamGameId].forEach(id => {
        delete objects[id];
      });

      delete teamGameObjectList[teamGameId];
      delete teamGameObjectIdList[teamGameId];
      Vue.set(state.data, "objects", objects);

      Vue.set(state.data.teamGameObjectIdList, teamGameId, teamGameObjectIdList);
      Vue.set(state.data.teamGameObjectList, teamGameId, teamGameObjectList);

    },
    submitTeamGamePlayerMutationSuccess(state, { data, page }) {
      
      const stateData = INIT_DATA.copy(state.data);
      stateData.objects[data.id] = data;

      let objectArray = [];
      let objectIdArray = [];

      if (data.team_game_id in stateData.teamGameObjectIdList) {
        objectArray = stateData.teamGameObjectList[data.team_game_id];
        objectIdArray = stateData.teamGameObjectIdList[data.team_game_id];
      }

      const idx = objectIdArray.indexOf(data.id);
      if (idx < 0) {
        // Add
        objectIdArray.push(data.id);
      } else {
        // Update
        objectIdArray.splice(idx, 1, data.id);
      }

      let idx2 = -1;
      for (let i = 0; i < objectArray.length; i++) {
        if (objectArray[i].id === data.id) {
          idx2 = i;
          break;
        }
      }
      if (idx2 < 0) {
        // Add
        objectArray.push(data);
      } else {
        // Update
        objectArray.splice(idx2, 1, data);
      }

      stateData.teamGameObjectIdList[data.team_game_id] = objectIdArray;
      stateData.teamGameObjectList[data.team_game_id] = objectArray;

      saveState('teamGamePlayer', state, stateData);

      Nprogress.done();

      if (page) {
        Vue.notify({
          group: 'topCenterNotification',
          type: 'success',
          text: 'Team game player added',
        });

        page.closeDialog();
      }
    },
    // eslint-disable-next-line
    submitTeamGamePlayerMutationFailure(state, error) {
      Nprogress.done();
      Vue.notify({
        group: 'topCenterNotification',
        type: 'error',
        text: 'Team game player failed to add'
      });
    },
    logoutUserMutation(state) {
      Vue.set(state, "data", INIT_DATA.copy(MY_INIT_DATA.teamGamePlayer));
    },
  },
};
