<script>
/*eslint global-require: "error"*/

import SITE from "../../constants/site";

export default {
  data() {
    return {
      SITE: SITE,
    };
  },
  created() {
    if (this.$isAuthenticated) {
      // TODO - change this to dashboard
      this.$router.replace({ name: "sites" });
      // window.location.replace("/sites");
    }
  },
};
</script>

<template>
  <div class="home">
    <!-- Start Language Banner Area -->
    <!-- section class="language-banner-area">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-12">
            <div class="language-banner-content">
              <h1>{{ SITE.data.languageBannerArea.title }}</h1>
              <p v-text="SITE.data.languageBannerArea.text" />
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="language-banner-image">
              <img
                :src="SITE.data.languageBannerArea.image.url"
                :alt="SITE.data.languageBannerArea.image.alt"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="lang-shape1">
        <img src="assets/img/lang-shape1.png" alt="image" />
      </div>
    </section -->
    <!-- End Language Banner Area -->

    <!-- Start Sites Area -->
    <!-- section class="sites-area pt-100 pb-70">
      <div class="container">
        <div class="section-title">
          <span class="sub-title">Welcome to ecademy</span>
          <h2>Our Language Sites</h2>
          <p>
            Explore all of our sites and pick your suitable ones to enroll and
            start learning with us! We ensure that you will never regret it!
          </p>
        </div>

        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="single-language-sites-box">
              <a href="sites-3-columns-style-1.html" class="d-block">
                <img src="assets/img/language-sites/1.jpg" alt="image" />
              </a>
              <div class="content">
                <h3><a href="sites-3-columns-style-1.html">Chinese</a></h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore.
                </p>
                <a href="sites-3-columns-style-1.html" class="link-btn"
                  >Read More</a
                >
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="single-language-sites-box">
              <a href="sites-3-columns-style-1.html" class="d-block">
                <img src="assets/img/language-sites/2.jpg" alt="image" />
              </a>
              <div class="content">
                <h3><a href="sites-3-columns-style-1.html">SPANISH</a></h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore.
                </p>
                <a href="sites-3-columns-style-1.html" class="link-btn"
                  >Read More</a
                >
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <div class="single-language-sites-box">
              <a href="sites-3-columns-style-1.html" class="d-block">
                <img src="assets/img/language-sites/3.jpg" alt="image" />
              </a>
              <div class="content">
                <h3><a href="sites-3-columns-style-1.html">JAPANESE</a></h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore.
                </p>
                <a href="sites-3-columns-style-1.html" class="link-btn"
                  >Read More</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section -->
    <!-- End Sites Area -->

    <!-- Start Why Choose Us Area -->
    <!-- section class="why-choose-us-area bg-e6fbff">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 col-md-12">
            <div class="why-choose-us-image">
              <img src="assets/img/why-choose-us.jpg" alt="image" />
            </div>
          </div>

          <div class="col-lg-6 col-md-12">
            <div class="why-choose-us-content">
              <div class="content">
                <span class="sub-title">Why Choose Us</span>
                <h2>Amazing Sites to Learn Language Better</h2>
                <p>
                  We understand better that online-based learning can make a
                  significant change to reach authenticateds from all over the world!
                  Giving options to learn better always can offer the best
                  outcomes!
                </p>
                <ul class="features-list">
                  <li>
                    <span
                      ><i class="flaticon-self-growth"></i> Skiled
                      Teachers</span
                    >
                  </li>
                  <li>
                    <span
                      ><i class="flaticon-clock"></i> Afordable Sites</span
                    >
                  </li>
                  <li>
                    <span
                      ><i class="flaticon-ebook"></i> Efficient & Flexible</span
                    >
                  </li>
                  <li>
                    <span
                      ><i class="flaticon-factory"></i> Lifetime Access</span
                    >
                  </li>
                </ul>
                <a href="register.html" class="default-btn"
                  ><i class="bx bx-user-circle icon-arrow before"></i
                  ><span class="label">Join For Free</span
                  ><i class="bx bx-user-circle icon-arrow after"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="lang-shape2">
        <img src="assets/img/lang-shape2.png" alt="image" />
      </div>
    </section -->
    <!-- End Why Choose Us Area -->

    <!-- Start Sites Area -->
    <!-- section class="sites-area pt-100">
      <div class="container">
        <div class="section-title">
          <h2>{{ SITE.data.sites.title }}</h2>
          <p v-text="SITE.data.sites.text" />
        </div>

        <div class="row">
          <div
            v-for="item in SITE.data.sites.rows"
            :key="item.id"
            class="col-lg-4 col-md-6"
          >
            <div class="sites-box">
              <div class="sites-image">
                <img :src="item.image.url" alt="image" />
                <div class="price shadow" v-text="item.price"></div>
              </div>
              <div class="sites-content">
                <h3>{{ item.name }}</h3>
                <p v-text="item.description" />
                <ul
                  class="sites-box-footer d-flex justify-content-between align-items-center"
                >
                  <li>
                    <i class="bx bx-book-bookmark"></i>
                    {{ item.numberOfPages }} Pages
                  </li>
                  <li>
                    <i class="bx bx-group"></i>
                    {{ item.numberOfAuthenticateds }} Authenticateds
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section -->
    <!-- End Sites Area -->

    <!-- Start Funfacts Area -->
    <!-- section class="funfacts-style-two pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="single-funfacts-box">
                            <h3><span class="odometer" data-count="1926">00</span></h3>
                            <p>Finished Sessions</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="single-funfacts-box">
                            <h3><span class="odometer" data-count="3279">00</span></h3>
                            <p>Enrolled Learners</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="single-funfacts-box">
                            <h3><span class="odometer" data-count="250">00</span></h3>
                            <p>Online Instructors</p>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="single-funfacts-box">
                            <h3><span class="odometer" data-count="100">00</span>%</h3>
                            <p>Satisfaction Rate</p>
                        </div>
                    </div>
                </div>
            </div>

            <div id="particles-js-circle-bubble"></div>
        </section -->
    <!-- End Funfacts Area -->

    <!-- Start Feedback Area -->
    <!-- section class="feedback-area-two pt-100">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Testimonials</span>
                    <h2>Our Authenticateds Feedback</h2>
                </div>

                <div class="feedback-slides owl-carousel owl-theme">
                    <div class="single-feedback-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                        <div class="info">
                            <div class="d-flex align-items-center justify-content-center">
                                <img src="assets/img/user1.jpg" alt="image">
                                <div class="title">
                                    <h3>John Smith</h3>
                                    <span>Python Developer</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="single-feedback-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                        <div class="info">
                            <div class="d-flex align-items-center justify-content-center">
                                <img src="assets/img/user2.jpg" alt="image">
                                <div class="title">
                                    <h3>Sarah Taylor</h3>
                                    <span>PHP Developer</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="single-feedback-box">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                        <div class="info">
                            <div class="d-flex align-items-center justify-content-center">
                                <img src="assets/img/user3.jpg" alt="image">
                                <div class="title">
                                    <h3>James Anderson</h3>
                                    <span>OOP Developer</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="feedback-image">
                <img src="assets/img/feedback-img.jpg" alt="image">
            </div>
        </section -->
    <!-- End Feedback Area -->

    <!-- Start Information Area -->
    <!-- section class="information-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="information-image text-center">
                            <img src="assets/img/information-img.png" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="information-content">
                            <span class="sub-title">Information</span>
                            <h2>How To Apply?</h2>
                            <ul class="apply-details">
                                <li>
                                    <div class="icon">
                                        <i class="flaticon-checkmark"></i>
                                    </div>
                                    <h3>Select Suitable Site</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                </li>

                                <li>
                                    <div class="icon">
                                        <i class="flaticon-login"></i>
                                    </div>
                                    <h3>Authenticated Information</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                </li>
                                
                                <li>
                                    <div class="icon">
                                        <i class="flaticon-credit-card"></i>
                                    </div>
                                    <h3>Payment Information</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                </li>
                                
                                <li>
                                    <div class="icon">
                                        <i class="flaticon-verify"></i>
                                    </div>
                                    <h3>Register Now</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section -->
    <!-- End Information Area -->

    <!-- Start Events Area -->
    <!-- section class="events-area bg-f9f9f9 pt-100 pb-70">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Events</span>
                    <h2>Our Upcoming Events</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-6">
                        <div class="events-box">
                            <div class="row m-0">
                                <div class="col-lg-5 col-md-5 p-0">
                                    <div class="image bg1">
                                        <img src="assets/img/business-coaching/events-img1.jpg" alt="image">

                                        <div class="divider-shape"></div>
                                    </div>
                                </div>

                                <div class="col-lg-7 col-md-7 p-0">
                                    <div class="content">
                                        <div class="date">
                                            <span>Wed, 03 June, 2020</span>
                                        </div>
                                        <h3><a href="#">Music Conference</a></h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                        <span class="location"><i class="bx bx-map">Vancover, Canada</i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-6">
                        <div class="events-box">
                            <div class="row m-0">
                                <div class="col-lg-5 col-md-5 p-0">
                                    <div class="image bg2">
                                        <img src="assets/img/business-coaching/events-img2.jpg" alt="image">

                                        <div class="divider-shape"></div>
                                    </div>
                                </div>

                                <div class="col-lg-7 col-md-7 p-0">
                                    <div class="content">
                                        <div class="date">
                                            <span>Thu, 04 June, 2020</span>
                                        </div>
                                        <h3><a href="#">Paper Plates Art</a></h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                        <span class="location"><i class="bx bx-map">Sydney, Australia</i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-6">
                        <div class="events-box">
                            <div class="row m-0">
                                <div class="col-lg-5 col-md-5 p-0">
                                    <div class="image bg3">
                                        <img src="assets/img/business-coaching/events-img3.jpg" alt="image">

                                        <div class="divider-shape"></div>
                                    </div>
                                </div>

                                <div class="col-lg-7 col-md-7 p-0">
                                    <div class="content">
                                        <div class="date">
                                            <span>Fri, 05 June, 2020</span>
                                        </div>
                                        <h3><a href="#">Imagination Classes</a></h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                        <span class="location"><i class="bx bx-map">Istanbul, Turkey</i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12 col-sm-6">
                        <div class="events-box">
                            <div class="row m-0">
                                <div class="col-lg-5 col-md-5 p-0">
                                    <div class="image bg4">
                                        <img src="assets/img/business-coaching/events-img4.jpg" alt="image">

                                        <div class="divider-shape"></div>
                                    </div>
                                </div>

                                <div class="col-lg-7 col-md-7 p-0">
                                    <div class="content">
                                        <div class="date">
                                            <span>Sat, 06 June, 2020</span>
                                        </div>
                                        <h3><a href="#">Number Matching</a></h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                        <span class="location"><i class="bx bx-map">New York, USA</i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="business-shape7"><img src="assets/img/business-coaching/business-shape4.png" alt="image"></div>
        </section -->
    <!-- End Events Area -->

    <!-- Start Free Trial Area -->
    <!-- section class="free-trial-area jarallax" data-jarallax='{"speed": 0.3}'>
            <div class="container">
                <div class="free-trial-form">
                    <span class="sub-title">Free Trial</span>
                    <h2>Sign Up For A Free Trial</h2>
                    <form>
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Name *">
                        </div>

                        <div class="form-group">
                            <input type="email" class="form-control" placeholder="Your Email *">
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Phone *">
                        </div>

                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Your Subject *">
                        </div>

                        <button type="submit">Register Now</button>
                    </form>
                </div>
            </div>

            <div class="lang-shape3"><img src="assets/img/lang-shape3.png" alt="image"></div>
        </section -->
    <!-- End Free Trial Area -->

    <!-- Start Blog Area -->
    <!-- section class="blog-area pt-100 pb-70">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Our News</span>
                    <h2>Check Out Our Latest Blog</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>

                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post-box without-bg-color">
                            <div class="post-image">
                                <a href="#" class="d-block">
                                    <img src="assets/img/business-coaching/blog-img1.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <a href="#" class="category">MBA</a>
                                <h3><a href="#">Why a Blog Gets You Further than an MBA</a></h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6">
                        <div class="single-blog-post-box without-bg-color">
                            <div class="post-image">
                                <a href="#" class="d-block">
                                    <img src="assets/img/business-coaching/blog-img2.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <a href="#" class="category">Business</a>
                                <h3><a href="#">Why Your Small Business Must Start a Blog</a></h3>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                        <div class="single-blog-post-box without-bg-color">
                            <div class="post-image">
                                <a href="#" class="d-block">
                                    <img src="assets/img/business-coaching/blog-img3.jpg" alt="image">
                                </a>
                            </div>

                            <div class="post-content">
                                <a href="#" class="category">Blogger</a>
                                <h3><a href="#">Become a Better Blogger Today With Us</a></h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section -->
    <!-- End Blog Area -->

    <!-- Start Partner Area -->
    <!-- section class="partner-area bg-color ptb-100">
            <div class="container">
                <div class="partner-slides owl-carousel owl-theme">
                    <div class="single-partner-item">
                        <a href="#" class="d-block">
                            <img src="assets/img/partner/1.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="#" class="d-block">
                            <img src="assets/img/partner/2.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="#" class="d-block">
                            <img src="assets/img/partner/3.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="#" class="d-block">
                            <img src="assets/img/partner/4.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="#" class="d-block">
                            <img src="assets/img/partner/5.png" alt="image">
                        </a>
                    </div>

                    <div class="single-partner-item">
                        <a href="#" class="d-block">
                            <img src="assets/img/partner/6.png" alt="image">
                        </a>
                    </div>
                </div>
            </div>
        </section -->
    <!-- End Partner Area -->

    <!-- Start Subscribe Area -->
    <!-- section class="subscribe-area ptb-100">
      <div class="container">
        <div class="subscribe-content">
          <span class="sub-title">Go At Your Own Pace</span>
          <h2>Subscribe To Our Newsletter</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <form class="newsletter-form" data-toggle="validator">
            <input
              type="text"
              class="input-newsletter"
              placeholder="Enter your email address"
              name="EMAIL"
              required
              autocomplete="off"
            />
            <button type="submit" class="default-btn">
              <i class="bx bx-paper-plane icon-arrow before"></i
              ><span class="label">Subscribe Now</span
              ><i class="bx bx-paper-plane icon-arrow after"></i>
            </button>
            <div id="validator-newsletter" class="form-result"></div>
          </form>
        </div>
      </div>

      <div class="lang-shape4">
        <img src="assets/img/lang-shape4.png" alt="image" />
      </div>
      <div class="lang-shape5">
        <img src="assets/img/lang-shape5.png" alt="image" />
      </div>
    </section -->
    <!-- End Subscribe Area -->
  </div>
</template>

<style scoped src="@/assets/css/bootstrap..min.css"></style>
<style scoped src="@/assets/css/boxicons.min.css"></style>
<style scoped src="@/assets/css/flaticon.css"></style>
<style scoped src="@/assets/css/meanmenu.min.css"></style>
<style scoped src="@/assets/css/animate.min.css"></style>
<style scoped src="@/assets/css/owl.carousel.min.css"></style>
<style scoped src="@/assets/css/odometer.min.css"></style>
<style scoped src="@/assets/css/nice-select.min.css"></style>
<style scoped src="@/assets/css/viewer.min.css"></style>
<style scoped src="@/assets/css/slick.min.css"></style>
<style scoped src="@/assets/css/magnific-popup.min.css"></style>
<style scoped src="@/assets/css/style.css"></style>
<style scoped src="@/assets/css/responsive.css"></style>

<style>
.home {
  width: 100%;
}
</style>
