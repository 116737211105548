<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

import { EventBus } from '../../../plugins/event-bus';
import TEAM from '../../../constants/team';

export default {
  name: 'TeamGamePlayerForm',
  props: ['teamId', 'teamGamePlayerId', 'dialog'],
  data() {
    return {
      valid: false,
      player: null,
      gamePlayer: null,
      id: 0,
      notes: '',
      minTimePerRest: '',
      maxTimePerStint: '',
      submitBtnIsDisabled: false,
      messages: {
        minTimePerRest: '',
        maxTimePerStint: '',
      },
      TEAM: TEAM,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        if (this.teamGamePlayerId) {
          this.gamePlayer = this.$store.getters[
            'teamGamePlayer/getById'
          ](this.teamGamePlayerId);

          this.player = this.$store.getters[
            'teamPlayer/getById'
          ](this.gamePlayer.team_player_id);

          this.notes = this.gamePlayer.notes;
          this.minTimePerRest = this.gamePlayer.min_time_per_rest;
          this.maxTimePerStint = this.gamePlayer.max_time_per_stint;
        }
      } else {
        this.closeDialog();
      }
    },
  },
  mounted() {},
  computed: {
    isEditForm() {
      return this.teamGamePlayerId;
    },
  },
  methods: {
    validate() {
      this.clearMessages();
      this.valid = true;
      if (this.maxTimePerStint && this.maxTimePerStint < 1) {
        this.messages.maxTimePerStint = 'This should be more than 0';
        this.valid = false;
      }
      if (this.minTimePerRest && this.minTimePerRest < 1) {
        this.messages.minTimePerRest = 'This should be more than 0';
        this.valid = false;
      }
    },
    clearForm() {
      this.clearMessages();
      this.player = null;
      this.gamePlayer = null;
      this.notes = '';
      this.minTimePerRest = '';
      this.maxTimePerStint = '';
      this.id = 0;
    },
    clearMessages() {
      this.messages.minTimePerRest = '';
      this.messages.maxTimePerStint = '';
    },
    submit() {
      this.validate();
      if (!this.valid) return;

      const payload = {
        ...this.gamePlayer,
        notes: this.notes,
        max_time_per_stint: this.maxTimePerStint,
        min_time_per_rest: this.minTimePerRest,
        timestamp: new Date().getTime(),
      };

      let method;
      if (this.isEditForm) {
        method = 'put';
      } else {
        method = 'post';
      }

      this.$store.dispatch(
        'teamGamePlayer/submitTeamGamePlayerAction',
        {
          method: method,
          payload: payload,
          page: this,
        },
      );
    },
    closeDialog() {
      this.clearForm();
      EventBus.$emit(TEAM.events.closeTeamGamePlayerDialog);
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title v-if="isEditForm" class="headline"
        >Edit player</v-card-title
      >
      <v-card-title v-if="!isEditForm" class="headline"
        >Add player</v-card-title
      >

      <v-card-subtitle class="mt-5" v-html="TEAM.colorCode" />

      <v-form @keyup.enter.native="submit" v-model="valid" class="m-20">
        <v-text-field
          label="Name"
          disabled
          :value="player ? player.name : ''"
        ></v-text-field>
        
        <v-text-field
          label="Notes"
          type="textarea"
          v-model="notes"
          placeholder="Enter game notes"
        ></v-text-field>

        <v-text-field
          label="Maximum time per stint (in minutes)"
          type="number"
          v-model="maxTimePerStint"
          placeholder="Maximum minutes player should play per stint"
          :error-messages="messages.maxTimePerStint"
          @keyup="validate()"
        ></v-text-field>        
        <v-text-field
          label="Minimum time per rest (in minutes)"
          type="number"
          v-model="minTimePerRest"
          placeholder="Minimum minutes player should rest after play"
          :error-messages="messages.minTimePerRest"
          @keyup="validate()"
        ></v-text-field>        
        <!-- TODO - add positions and time allotment -->
        <!-- v-text-field
          label="Description (positions, time allotment etc)"
          v-model="description"
        ></v-text-field -->

        <!-- needed if only one field in form for keyup.enter to work -->
        <v-text-field style="display:none" />
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="white--text" color="grey darken-1" @click="closeDialog()"
          >Close</v-btn
        >
        <v-btn
          v-if="isEditForm"
          color="primary darken-1"
          @click="submit"
          >Edit player</v-btn
        >
        <v-btn
          v-if="!isEditForm"
          color="primary darken-1"
          @click="submit"
          >Add player</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>