export default {
  events: {
    closeTeamDialog: 'close-team-dialog',
    closeTeamGameDialog: 'close-team-game-dialog',
    closeTeamPlayerDialog: 'close-team-player-dialog',
    closeTeamGamePlayerDialog: 'close-team-game-player-dialog',
    closeUploadDownloadDeleteDataDialog: 'close-upload-download-delete-data-dialog',
    closeUploadDownloadDeleteDataAndReloadDialog: 'close-upload-download-delete-data-and-reload-dialog',
  },
  ordinal: {
    1: '1st',
    2: '2nd',
    3: '3rd',
    4: '4th',
    5: '5th',
    6: '6th',
    7: '7th',
    8: '8th',
    9: '9th',
    10: '10th',
  },
  period: {
    name: {
      'soccer': 'Half',
    },
    numberOfPeriods: {
      'soccer': 2,
    },
  },
  colorCode: 'When you enter the maximum time per stint and minimum time per rest, the app will show you a color code for when the player is tired. From <span class=\'tired-turquoise\'>turquoise (less tired)</span>, to <span class=\'tired-orange\'>orange</span>, to <span class=\'tired-violet\'>violet</span> and finally to <span class=\'tired-red\'>red (most tired)</span>',

};
