<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

import { EventBus } from '../../../plugins/event-bus';
import TEAM from '../../../constants/team';

export default {
  name: 'TeamGameForm',
  props: ['teamId', 'teamGameId', 'dialog'],
  data() {
    return {
      valid: false,
      // order: 0,
      id: 0,
      name: '',
      type: '',
      notes: '',
      timePerPeriod: '',
      numberOfPlayers: '',
      minTimePerRest: '',
      maxTimePerStint: '',
      submitBtnIsDisabled: false,
      messages: {
        name: '',
        type: '',
        timePerPeriod: '',
        numberOfPlayers: '',
        minTimePerRest: '',
        maxTimePerStint: '',
      },
      TEAM: TEAM,
    };
  },
  watch: {
    dialog(val) {
      if (val) {
        if (this.teamGameId) {
          const game = this.$store.getters[
            'teamGame/getById'
          ](this.teamGameId);
          this.name = game.name;
          this.type = game.type;
          this.notes = game.notes;
          this.timePerPeriod = game.time_per_period;
          this.numberOfPlayers = game.number_of_players;
          this.minTimePerRest = game.min_time_per_rest;
          this.maxTimePerStint = game.max_time_per_stint;
        }
      } else {
        this.closeDialog();
      }
    },
  },
  mounted() {},
  computed: {
    isEditForm() {
      return this.teamGameId;
    },
  },
  methods: {
    validate() {
      this.clearMessages();
      this.valid = true;
      if (!this.name) {
        this.messages.name = 'Game name is required';
        this.valid = false;
      }
      if (!this.type) {
        this.messages.type = 'Game type is required';
        this.valid = false;
      }
      if (!this.timePerPeriod) {
        this.messages.timePerPeriod = 'Time (in minutes) per period is required';
        this.valid = false;
      }
      if (this.timePerPeriod < 1) {
        this.messages.timePerPeriod = 'This should be more than 0';
        this.valid = false;
      }
      if (!this.numberOfPlayers) {
        this.messages.numberOfPlayers = 'Number of players in the game is required';
        this.valid = false;
      }
      if (this.numberOfPlayers < 1) {
        this.messages.numberOfPlayers = 'This should be more than 0';
        this.valid = false;
      }
      if (this.maxTimePerStint && this.maxTimePerStint < 1) {
        this.messages.maxTimePerStint = 'This should be more than 0';
        this.valid = false;
      }
      if (this.minTimePerRest && this.minTimePerRest < 1) {
        this.messages.minTimePerRest = 'This should be more than 0';
        this.valid = false;
      }      
    },
    clearForm() {
      this.clearMessages();
      this.name = '';
      this.type = '';
      this.notes = '';
      this.timePerPeriod = '';
      this.numberOfPlayers = '';
      this.minTimePerRest = '';
      this.maxTimePerStint = '';
      this.id = 0;
    },
    clearMessages() {
      this.messages.name = '';
      this.messages.type = '';
      this.messages.timePerPeriod = '';
      this.messages.numberOfPlayers = '';
      this.messages.minTimePerRest = '';
      this.messages.maxTimePerStint = '';
    },
    submit() {
      this.validate();
      if (!this.valid) return;

      const payload = {
        id: this.teamGameId ? this.teamGameId : this.$generateUUID(),
        team_id: this.teamId,
        name: this.name,
        type: this.type,
        notes: this.notes,
        number_of_players: this.numberOfPlayers,
        time_per_period: this.timePerPeriod,
        max_time_per_stint: this.maxTimePerStint,
        min_time_per_rest: this.minTimePerRest,
        current_period: 1,
        game_started: false,
        game_over: false,
        time_segments: {
          1: [],
        },
        timestamp: new Date().getTime(),
      };

      let method;
      if (this.isEditForm) {
        method = 'put';
      } else {
        method = 'post';
      }

      this.$store.dispatch(
        'teamGame/submitTeamGameAction',
        {
          method: method,
          payload: payload,
          page: this,
        },
      );
    },
    closeDialog() {
      this.clearForm();
      EventBus.$emit(TEAM.events.closeTeamGameDialog);
    },
  },
};
</script>

<template>
  <v-dialog v-model="dialog" persistent>
    <v-card>
      <v-card-title v-if="isEditForm" class="headline"
        >Edit game</v-card-title
      >
      <v-card-title v-if="!isEditForm" class="headline"
        >Add game</v-card-title
      >

      <v-card-subtitle class="mt-5" v-html="TEAM.colorCode" />

      <v-form @keyup.enter.native="submit" v-model="valid" class="m-20">
        <v-text-field
          label="Name"
          v-model="name"
          required
          :error-messages="messages.name"
          placeholder="Hint: You can use the game date as name"
          @keyup="validate()"
        ></v-text-field>
        <v-select
          :items="['Soccer']"
          label="Type"
          v-model="type"
          required
          :error-messages="messages.type"
        ></v-select>
        <v-text-field
          type="number"
          label="Number of players in a game"
          v-model="numberOfPlayers"
          required
          :error-messages="messages.numberOfPlayers"
          placeholder="Maximum number of your players playing at a time"
          @keyup="validate()"
        ></v-text-field>
        <v-text-field
          type="number"
          label="Time per period (minutes)"
          v-model="timePerPeriod"
          required
          :error-messages="messages.timePerPeriod"
          placeholder="Enter time per period in minutes"
          @keyup="validate()"
        ></v-text-field>

        <v-text-field
          label="Notes"
          type="textarea"
          v-model="notes"
          placeholder="Enter game notes"
        ></v-text-field>

        <v-text-field
          label="Maximum time per stint (minutes)"
          type="number"
          v-model="maxTimePerStint"
          placeholder="Maximum minutes player should play per stint"
          :error-messages="messages.maxTimePerStint"
          @keyup="validate()"
        ></v-text-field>        
        <v-text-field
          label="Minimum time per rest (minutes)"
          type="number"
          v-model="minTimePerRest"
          placeholder="Minum minutes player should rest after play"
          :error-messages="messages.minTimePerRest"
          @keyup="validate()"
        ></v-text-field>
      </v-form>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="white--text" color="grey darken-1" @click="closeDialog()"
          >Close</v-btn
        >
        <v-btn
          v-if="isEditForm"
          color="primary darken-1"
          @click="submit"
          >Edit game</v-btn
        >
        <v-btn
          v-if="!isEditForm"
          color="primary darken-1"
          @click="submit"
          >Add game</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>