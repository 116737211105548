<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

import Vue from 'vue';
import { INIT_DATA as MY_INIT_DATA } from "../../store/data";
import { EventBus } from '../../plugins/event-bus';
import TEAM from '../../constants/team';
import TeamGamePlayerForm from './dialogs/TeamGamePlayerForm.vue';

export default {
  name: "GamePlayers",
  components: { TeamGamePlayerForm },
  data() {
    return {
      dialog: false,
      dialogTeamGamePlayerId: '',
      timers: {
        half: '',
        total: '',
        players: {},
      },
      updating: false,
      gamePlayerTimerInterval: null,
      dataTableItems: {
        in_game: [],
        out_of_game: [],
      },
      selectedInGameDataTableItems: [],
      selectedOutOfGameDataTableItems: [],
    };
  },
  beforeDestroy() {
    EventBus.$off(TEAM.events.closeTeamGamePlayerDialog);
  },
  created() {
    EventBus.$on(
      TEAM.events.closeTeamGamePlayerDialog,
      // eslint-disable-next-line
      arg => {
        this.updateClocks();
        this.closeDialog();
      },
    );

    this.initialize();
    /*
    this.$getAllGamePlayersProgressAndResponsesFromServer(
      "teams",
      this.teamGameId
    );
    */
  },
  mounted() {
    this.updateClocks();
  },
  computed: {
    hasPhotos() {
      return this.teamPlayers.filter(item => item.photo_url).length > 0;
    },
    hasNotes() {
      return this.teamPlayers.filter(item => item.notes).length > 0;
    },
    teamId() {
      return this.$route.params.team_id;
    },
    team() {
      return this.$store.getters[
        'team/getById'
      ](this.teamId);
    },
    confirmOnChanges() {
      return this.team.confirm_on_changes;
    },
    teamGameId() {
      return this.$route.params.team_game_id;
    },
    teamGame() {
      return this.$store.getters[
        'teamGame/getById'
      ](this.teamGameId);
    },
    teamPlayers() {
      const teamPlayers = this.$store.getters[
        'teamPlayer/getByTeamId'
      ](this.teamId);
      if (teamPlayers) {
        teamPlayers.sort((a, b) => (b.timestamp > a.timestamp ? 1 : -1));
        return teamPlayers;
      }
      return [];
    },
    teamGamePlayerId() {
      return this.$route.params.team_game_player_id;
    },
    teamGamePlayers() {
      if (this.teamGamePlayerId) {
        const teamGamePlayer = this.$store.getters[
          'teamGamePlayer/getById'
        ](this.teamGamePlayerId);
        if (teamGamePlayer) {
          return [teamGamePlayer];
        }
      } else {
        const teamGamePlayers = this.$store.getters[
          'teamGamePlayer/getByTeamGameId'
        ](this.teamGameId);
        if (teamGamePlayers) {
          // TODO - change order to timestamp ??
          teamGamePlayers.sort((a, b) => (b.timestamp > a.timestamp ? 1 : -1));
          return teamGamePlayers;
        }
      }
      return [];
    },
    teamGamePlayersInGame() {
      const teamGamePlayers = this.$copy(this.teamGamePlayers);
      teamGamePlayers.sort((a, b) => (b.timestamp > a.timestamp ? 1 : -1));
      return teamGamePlayers.filter(player => player.in_game);
    },
    teamGamePlayersOutOfGame() {
      const teamGamePlayers = this.$copy(this.teamGamePlayers);
      teamGamePlayers.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1));
      return teamGamePlayers.filter(player => !player.in_game);
    },
    teamGameTimeSegmentLength() {
      return this.teamGame.time_segments[this.currentPeriod].length;
    },
    currentPeriod() {
      return this.teamGame.current_period;
    },
    timerIsStopped() {
      // When we stop we updated the last segment with stop_timestamp=timestamp
      if (this.teamGame.game_over || !this.teamGameTimeSegmentLength) return true;

      // Get the last segment
      const segment = this.teamGame.time_segments[this.currentPeriod][this.teamGameTimeSegmentLength-1];
      if (segment.stop_timestamp) return true;

      return false;
    },
    timerIsStarted() {
      // When we start we just add a new segment with start_timestamp=timestamp
      if (this.teamGame.game_over || !this.teamGameTimeSegmentLength) return false;

      // Get the last segment
      const segment = this.teamGame.time_segments[this.currentPeriod][this.teamGameTimeSegmentLength-1];
      if (segment.start_timestamp) return true;

      return false;
    },
    timerIsPaused() {
      // When we pause we just add a new segment with start_timestamp=0
      if (this.teamGame.game_over || !this.teamGameTimeSegmentLength) return false;

      // Get the last segment
      const segment = this.teamGame.time_segments[this.currentPeriod][this.teamGameTimeSegmentLength-1];
      if (!segment.start_timestamp) return true;

      return false;
    },
    numberOfPeriods() {
      return this.teamGame && this.teamGame.type ? TEAM.period.numberOfPeriods[this.teamGame.type.toLowerCase()] : 0;
    },
    dataTableHeadersOrig() {
      return {
        in_game: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Photo', value: 'photo_url', sortable: false },
          { text: 'Notes', value: 'notes', sortable: false, },
          { text: 'Current stint time', value: 'current_stint_time', sortable: true },
          { text: `${this.getPeriodLabel()} Total Time In`, value: 'current_period_total_time_in', sortable: true },
          { text: 'Total Time In Game', value: 'total_time_in_game', sortable: true },
          { text: `${this.getPeriodLabel()} Total Time Out`, value: 'current_period_total_time_out', sortable: true },
          { text: 'Total Time Out Of Game', value: 'total_time_out_of_game', sortable: true },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        out_of_game: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Photo', value: 'photo_url', sortable: false },
          { text: 'Notes', value: 'notes', sortable: false, },
          { text: 'Time out since last stint', value: 'time_out_since_last_stint', sortable: true },
          { text: `${this.getPeriodLabel()} Total Time In`, value: 'current_period_total_time_in', sortable: true },
          { text: 'Total Time In Game', value: 'total_time_in_game', sortable: true },
          { text: `${this.getPeriodLabel()} Total Time Out`, value: 'current_period_total_time_out', sortable: true },
          { text: 'Total Time Out Of Game', value: 'total_time_out_of_game', sortable: true },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
      };
    },
    dataTableHeaders() {
      const headers = {
        in_game: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
        ],
        out_of_game: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
        ],
      };

      const inOrOut = ['in_game', 'out_of_game'];

      inOrOut.forEach(x => {
        if (this.hasPhotos) {
          headers[x].push(
            {
              text: 'Photo',
              value: 'photo_url', 
              sortable: false
            },
          );
        }
        if (this.hasNotes) {
          headers[x].push(
            {
              text: 'Notes',
              value: 'notes', 
              sortable: false
            },
          );
        }

        if (x === 'in_game') {
          headers[x].push(
            {
              text: 'Current stint time',
              value: 'current_stint_time',
              sortable: true
            },
          );
        } else {
          headers[x].push(
            {
              text: 'Time out since last stint',
              value: 'time_out_since_last_stint', 
              sortable: true
            },
          );
        }

        headers[x].push(
          {
            text: `${this.getPeriodLabel()} Total Time In`,
            value: 'current_period_total_time_in', 
            sortable: true
          },
        );
        headers[x].push(
          {
            text: `${this.getPeriodLabel()} Total Time Out`,
            value: 'current_period_total_time_out', 
            sortable: true
          },
        );

        if (!this.$isMobile) {
          if (x === 'in_game') {
            headers[x].push(
              {
                text: 'Total Time In Game',
                value: 'total_time_in_game', 
                sortable: true
              },
            );
            headers[x].push(
              {
                text: 'Total Time Out Of Game',
                value: 'total_time_out_of_game', 
                sortable: true
              },
            );
          } else {
            headers[x].push(
              {
                text: 'Total Time In Game',
                value: 'total_time_in_game', 
                sortable: true
              },
            );
            headers[x].push(
              {
                text: 'Total Time Out Of Game',
                value: 'total_time_out_of_game', 
                sortable: true
              },
            );
          }
        }

        headers[x].push(
          {
            text: 'Actions',
            value: 'actions', 
            sortable: false
          },
        );

      });

      return headers;
    },
  },
  methods: {
    goToDetail(id) {
      this.$router.push({
        name: "team_game_player_detail",
        params: {
          game_id: this.$route.params.game_id,
          game_player_id: id
        }
      });
    },
    update(payload) {
      payload.timestamp = new Date().getTime();
      this.$store.dispatch(
        'teamGamePlayer/submitTeamGamePlayerAction',
        {
          payload: payload,
        },
      );
    },
    getInitTimeSegment() {
      const initTimeSegment = {
        period: this.currentPeriod,
        start_timestamp: 0, 
        stop_timestamp: 0,
      };

      if (this.timerIsStarted) {
        initTimeSegment.start_timestamp = new Date().getTime();
      } else if (this.timerIsPaused) {
        initTimeSegment.start_timestamp = 0;
      } else if (this.timerIsStarted) {
        initTimeSegment.stop_timestamp = new Date().getTime();
      }

      return initTimeSegment;
    },
    getOrCreate(teamPlayerId) {
      for (let i = 0; i < this.teamGamePlayers.length; i++) {
        const obj = this.teamGamePlayers[i];
        if (obj.team_player_id == teamPlayerId) {
          return obj;
        }
      }

      const teamPlayer = this.getTeamPlayer(teamPlayerId)

      // Create teamGamePlayer
      const payload = {
        ...MY_INIT_DATA.teamGamePlayer.objects[0],
        id: this.$generateUUID(),
        team_game_id: this.teamGameId,
        team_player_id: teamPlayerId,

        min_time_per_rest: teamPlayer.min_time_per_rest ? teamPlayer.min_time_per_rest : (this.teamGame.min_time_per_rest ? this.teamGame.min_time_per_rest : ''), // Copied from teamPlayer or teamGame
        max_time_per_stint: teamPlayer.max_time_per_stint ? teamPlayer.max_time_per_stint : (this.teamGame.max_time_per_stint ? this.teamGame.max_time_per_stint : ''), // Copied from teamPlayer or teamGame

        in_game: false,
        time_segments: {
          1: {
            in_game: [],
            out_of_game: [[this.getInitTimeSegment()]]
          }
        },
    
        timestamp: new Date().getTime(),
      };

      this.$store.dispatch(
        'teamGamePlayer/submitTeamGamePlayerAction',
        {
          payload: payload,
        },
      );

      return payload;
    },
    initialize() {
      this.teamPlayers.forEach(obj => {
        this.getOrCreate(obj.id);
      });
    },
    getTeamPlayer(teamPlayerId) {
      return this.$store.getters[
        'teamPlayer/getById'
      ](teamPlayerId);
    },
    changeInGameState(teamGamePlayerId) {
      const teamGamePlayerData = this.$copy(this.$store.getters[
        'teamGamePlayer/getById'
      ](teamGamePlayerId));

      const isInGame = !teamGamePlayerData.in_game;

      if (isInGame && this.teamGamePlayersInGame.length >= this.teamGame.number_of_players) {
        Vue.notify({
          group: "topRightNotification",
          type: "error",
          text: `You can not add more than ${this.teamGame.number_of_players} players in a game.`,
        });

        return;
      }

      /*
      if (this.confirmOnChanges && !confirm(`Are you sure you want to ${isInGame ? 'add' : 'remove'} ${this.getTeamPlayer(teamGamePlayerData.team_player_id).name}`)) {
        return;
      }
      */

      let addSegment = true;

      const playSegmentType = isInGame ? 'in_game' : 'out_of_game';

      if (!teamGamePlayerData.time_segments[this.currentPeriod]) {
        teamGamePlayerData.time_segments[this.currentPeriod] = {};
      }

      if (!teamGamePlayerData.time_segments[this.currentPeriod][playSegmentType]) {
        teamGamePlayerData.time_segments[this.currentPeriod][playSegmentType] = [];
      }
      const playTimeSegments = teamGamePlayerData.time_segments[this.currentPeriod][playSegmentType];

      let lastPlayTimeSegmentArray = [];
      let lastPlayTimeSegment = {};

      const previousPlaySegmentType = !isInGame ? 'in_game' : 'out_of_game';
      const previousPlayTimeSegments = teamGamePlayerData.time_segments[this.currentPeriod][previousPlaySegmentType];
      let previousLastPlayTimeSegmentArray = [];
      let previousLastPlayTimeSegment = {};

      // Ensure that we set stop_timestamp in the previous play segment
      // In other words - if user was previously out_of_game - then set
      // the out of game last segment stop_timestamp before you create new
      // time segments for the in_game data 
      if (previousPlayTimeSegments.length) {
        // Get last segment
        previousLastPlayTimeSegmentArray = previousPlayTimeSegments[previousPlayTimeSegments.length-1];
        previousLastPlayTimeSegment = previousLastPlayTimeSegmentArray[previousLastPlayTimeSegmentArray.length-1];
        //if (!previousLastPlayTimeSegment.start_timestamp && !previousLastPlayTimeSegment.stop_timestamp) {
        //  addSegment = false;
        //}
        if (!previousLastPlayTimeSegment.stop_timestamp) {
          // Stop the timestamp
          previousLastPlayTimeSegment.stop_timestamp = new Date().getTime();
          previousLastPlayTimeSegmentArray[previousLastPlayTimeSegmentArray.length-1] = previousLastPlayTimeSegment;
          previousPlayTimeSegments[previousPlayTimeSegments.length-1] = previousLastPlayTimeSegmentArray;

          teamGamePlayerData.time_segments[this.currentPeriod][previousPlaySegmentType] = previousPlayTimeSegments;
        }

      }

      if (playTimeSegments.length) {
        // Get last segment
        // Is TODO below done??
        // TODO - when we start / pause / unpause /stop timer
        // we will be adding initTimeSegments to lastPlayTimeSegmentArray
        lastPlayTimeSegmentArray = playTimeSegments[playTimeSegments.length > 0 ?  playTimeSegments.length-1 : 0];
        lastPlayTimeSegment = lastPlayTimeSegmentArray[lastPlayTimeSegmentArray.length-1];
        if (!lastPlayTimeSegment.start_timestamp && !lastPlayTimeSegment.stop_timestamp) {
          addSegment = false;
        }
      }

      if (addSegment) {

        if (!lastPlayTimeSegment.stop_timestamp) {
          // Stop the timestamp before you add a new segment
          lastPlayTimeSegment.stop_timestamp = new Date().getTime();
          lastPlayTimeSegmentArray[lastPlayTimeSegmentArray.length-1] = lastPlayTimeSegment;
          playTimeSegments[playTimeSegments.length > 0 ?  playTimeSegments.length-1 : 0] = lastPlayTimeSegmentArray;
        }

        const initTimeSegment = this.getInitTimeSegment();

        playTimeSegments.push([
          initTimeSegment
        ]);

        teamGamePlayerData.time_segments[this.currentPeriod][playSegmentType] = playTimeSegments;
      }

      teamGamePlayerData.in_game = isInGame;
      this.update(teamGamePlayerData);

    },
    startTimerInterval() {
      this.gamePlayerTimerInterval = setInterval(
        () => (this.updateClocks()),
        1000,
      );
    },
    validateTimer() {
      if (this.teamGame.game_over){
        Vue.notify({
          group: "topRightNotification",
          type: "error",
          text: 'The game is over.',
        });
        return false;
      }
      return true;
    },
    startGameTimer() {
      if (!this.validateTimer()) return
      if (this.confirmOnChanges && !confirm('Are you sure you want to start timer?')) {
        return;
      }

      const timeSegment = {
        period: this.currentPeriod,
        start_timestamp: new Date().getTime(),
        stop_timestamp: 0,
      };

      const teamGameData = this.$copy(this.teamGame);

      teamGameData.game_started = true;

      teamGameData.time_segments[this.currentPeriod].push(timeSegment);

      this.$store.dispatch(
        'teamGame/submitTeamGameAction',
        {
          method: 'put',
          payload: teamGameData,
          page: null,
        },
      );

      this.updateGamePlayerTimers('start');
    },
    pauseGameTimer() {
      if (!this.validateTimer()) return
      if (this.confirmOnChanges && !confirm('Are you sure you want to pause timer?')) {
        return;
      }

      const timeSegment = {
        period: this.currentPeriod,
        start_timestamp: 0,
        stop_timestamp: 0,
      };

      const teamGameData = this.$copy(this.teamGame);

      const lastSegment = teamGameData.time_segments[teamGameData.current_period][this.teamGameTimeSegmentLength - 1];

      // Update lastSegment stop_timestamp
      lastSegment.stop_timestamp = new Date().getTime();
      teamGameData.time_segments[teamGameData.current_period][this.teamGameTimeSegmentLength - 1] = lastSegment;

      // Push new segment with start_timestamp=0
      teamGameData.time_segments[this.currentPeriod].push(timeSegment);

      this.$store.dispatch(
        'teamGame/submitTeamGameAction',
        {
          method: 'put',
          payload: teamGameData,
          page: null,
        },
      );

      this.updateGamePlayerTimers('pause');

      this.stopTimerInterval();
    },
    unPauseGameTimer() {
      if (!this.validateTimer()) return
      if (this.confirmOnChanges && !confirm('Are you sure you want to unpause timer?')) {
        return;
      }

      const teamGameData = this.$copy(this.teamGame);

      const lastSegment = teamGameData.time_segments[teamGameData.current_period][this.teamGameTimeSegmentLength - 1];

      // Update lastSegment start_timestamp
      lastSegment.start_timestamp = new Date().getTime();
      teamGameData.time_segments[teamGameData.current_period][this.teamGameTimeSegmentLength - 1] = lastSegment;

      this.$store.dispatch(
        'teamGame/submitTeamGameAction',
        {
          method: 'put',
          payload: teamGameData,
          page: null,
        },
      );

      this.updateGamePlayerTimers('unpause');
    },
    stopGamePeriodTimer() {
      if (!this.validateTimer()) return
      if (this.confirmOnChanges && !confirm(`Are you sure you want to stop the ${this.getPeriodLabel()} timer?`)) {
        return;
      }

      const newPeriod = this.numberOfPeriods > this.currentPeriod ? this.currentPeriod + 1 : this.currentPeriod;

      const teamGameData = this.$copy(this.teamGame);

      const lastSegment = teamGameData.time_segments[teamGameData.current_period][this.teamGameTimeSegmentLength - 1];

      // Update lastSegment stop_timestamp
      lastSegment.stop_timestamp = new Date().getTime();
      teamGameData.time_segments[teamGameData.current_period][this.teamGameTimeSegmentLength - 1] = lastSegment;

      if (newPeriod === this.currentPeriod) {
        teamGameData.game_over = true;
      } else {
        teamGameData.current_period = newPeriod;
        teamGameData.time_segments[newPeriod] = [];
      }

      this.$store.dispatch(
        'teamGame/submitTeamGameAction',
        {
          method: 'put',
          payload: teamGameData,
          page: null,
        },
      );

      this.updateGamePlayerTimers('stop');

      this.stopTimerInterval();
    },
    updateGamePlayerTimers(type) {
      // Now add to playTimeSegments
      for (let i = 0; i < this.teamGamePlayers.length; i++) {
        const teamGamePlayer = this.$store.getters[
          'teamGamePlayer/getById'
        ](this.teamGamePlayers[i].id);

        this.updateGamePlayerTimerHelper(type, teamGamePlayer);
      }

    },
    updateGamePlayerTimerHelper(type, teamGamePlayer) {
      
      const timestamp = new Date().getTime();

      let initTimeSegment = {}
      if (type === 'start') {
        initTimeSegment = {
          period: this.currentPeriod,
          start_timestamp: timestamp,
          stop_timestamp: 0,
        };
      } else if (type === 'pause') {
        initTimeSegment = {
          period: this.currentPeriod,
          start_timestamp: 0,
          stop_timestamp: 0,
        };
      }

      const teamGamePlayerData = this.$copy(teamGamePlayer);

      const playSegmentType = teamGamePlayerData.in_game ? 'in_game' : 'out_of_game';

      if (!teamGamePlayerData.time_segments[this.currentPeriod]) {
        teamGamePlayerData.time_segments[this.currentPeriod] = {};
      }

      if (!teamGamePlayerData.time_segments[this.currentPeriod][playSegmentType]) {
        teamGamePlayerData.time_segments[this.currentPeriod][playSegmentType] = [];
      }
      const playTimeSegments = teamGamePlayerData.time_segments[this.currentPeriod][playSegmentType];

      const lastPlayTimeSegmentArray = playTimeSegments.length ? playTimeSegments[playTimeSegments.length > 0 ?  playTimeSegments.length-1 : 0] : [initTimeSegment];
      let lastPlayTimeSegment = lastPlayTimeSegmentArray[lastPlayTimeSegmentArray.length-1];

      if (type === 'start') {

        if (!('start_timestamp' in lastPlayTimeSegment)) {
          lastPlayTimeSegment.start_timestamp = 0;
          lastPlayTimeSegment.stop_timestamp = 0;
        }

        if (lastPlayTimeSegment.start_timestamp && lastPlayTimeSegment.stop_timestamp) {
          // Add new segment
          lastPlayTimeSegmentArray.push(initTimeSegment);
        } else {
          // Start the timer
          lastPlayTimeSegment.start_timestamp = timestamp;
          lastPlayTimeSegmentArray[lastPlayTimeSegmentArray.length-1] = lastPlayTimeSegment;
        }

      } else if (['pause', 'stop'].indexOf(type) > -1) {

        if (!('stop_timestamp' in lastPlayTimeSegment)) {
          lastPlayTimeSegment.start_timestamp = timestamp;
          lastPlayTimeSegment.stop_timestamp = 0;
        }

        // Update lastSegment stop_timestamp
        lastPlayTimeSegment.stop_timestamp = timestamp;
        lastPlayTimeSegmentArray[lastPlayTimeSegmentArray.length-1] = lastPlayTimeSegment;

        if (type === 'pause') {
          // Now add another time segment to the array with start_timestamp=0
          lastPlayTimeSegmentArray.push(initTimeSegment);
        }

        if (type === 'stop' && this.currentPeriod >  1) {

          // We need to stop the previous period' lastPlayTimeSegment
          const lastPeriodPlayTimeSegments = teamGamePlayerData.time_segments[this.currentPeriod - 1][playSegmentType];

          const lastPeriodLastPlayTimeSegmentArray = lastPeriodPlayTimeSegments.length ? lastPeriodPlayTimeSegments[lastPeriodPlayTimeSegments.length-1] : [initTimeSegment];
          let lastPeriodLastPlayTimeSegment = lastPeriodLastPlayTimeSegmentArray[lastPeriodLastPlayTimeSegmentArray.length-1];

          // Update lastSegment stop_timestamp
          if (!lastPeriodLastPlayTimeSegment.stop_timestamp) {
            lastPeriodLastPlayTimeSegment.stop_timestamp = timestamp;
            lastPeriodLastPlayTimeSegmentArray[lastPeriodLastPlayTimeSegmentArray.length-1] = lastPeriodLastPlayTimeSegment;

            lastPeriodPlayTimeSegments[lastPeriodPlayTimeSegments.length-1] = lastPeriodLastPlayTimeSegmentArray;
            teamGamePlayerData.time_segments[this.currentPeriod-1][playSegmentType] = lastPeriodPlayTimeSegments;
          }
      
        }

      } else if (type === 'unpause') {

        // Update lastSegment start_timestamp
        lastPlayTimeSegment.start_timestamp = timestamp;
        lastPlayTimeSegmentArray[lastPlayTimeSegmentArray.length-1] = lastPlayTimeSegment;
      }

      playTimeSegments[playTimeSegments.length > 0 ?  playTimeSegments.length-1 : 0] = lastPlayTimeSegmentArray;
      teamGamePlayerData.time_segments[this.currentPeriod][playSegmentType] = playTimeSegments;

      this.update(teamGamePlayerData);
    },
    getTotalSegmentTime(timeSegments=[]) {
      let total = 0;
      timeSegments.forEach(obj => {
        if (obj.start_timestamp) {
          if (obj.stop_timestamp) {
            total += obj.stop_timestamp - obj.start_timestamp;
          } else {
            total += new Date().getTime() - obj.start_timestamp;
          }
        }
      })
      return total;
    },
    updateClocks() {
      if (this.updating) return;
      this.updating = true;

      const teamGameData = this.$copy(this.teamGame);

      let total = 0;
      for (let i=0; i <= this.numberOfPeriods; i++) {
        const period = i+1;
        total += teamGameData.time_segments && teamGameData.time_segments[period] ? this.getTotalSegmentTime(teamGameData.time_segments[period]) : 0;
      }

      const periodTotal = this.getTotalSegmentTime(teamGameData.time_segments[this.currentPeriod]);
      
      this.timers.period = `${this.getPeriodLabel()} Timer: ${new Date(periodTotal).toISOString().substr(11, 8)}`;
      this.timers.total = `Total Game Timer: ${new Date(total).toISOString().substr(11, 8)}`;

      const dataTableItems = {
        in_game: [],
        out_of_game: [],
      };

      // Now go through players and update viewed timer
      for (let i = 0; i < this.teamGamePlayers.length; i++) {
        const dataTableItem = {};
        const teamGamePlayerId = this.teamGamePlayers[i].id;
        const teamGamePlayerData = this.$copy(this.$store.getters[
          'teamGamePlayer/getById'
        ](teamGamePlayerId));

        const teamPlayer = this.getTeamPlayer(teamGamePlayerData.team_player_id);
        dataTableItem.name = teamPlayer.name;
        dataTableItem.notes = teamGamePlayerData.notes;
        dataTableItem.team_game_player_id = teamGamePlayerId;

        if (!teamGamePlayerData.time_segments[this.currentPeriod]) {
          teamGamePlayerData.time_segments[this.currentPeriod] = {};
        }

        if (!teamGamePlayerData.time_segments[this.currentPeriod]['in_game']) {
          teamGamePlayerData.time_segments[this.currentPeriod]['in_game'] = [];
        }

        if (!teamGamePlayerData.time_segments[this.currentPeriod]['out_of_game']) {
          teamGamePlayerData.time_segments[this.currentPeriod]['out_of_game'] = [];
        }

        const inGamePlayTimeSegments = teamGamePlayerData.time_segments[this.currentPeriod].in_game;
        const inGameLastPlayTimeSegmentArray = inGamePlayTimeSegments[inGamePlayTimeSegments.length-1];
        const inGameCurrent = this.getTotalSegmentTime(inGameLastPlayTimeSegmentArray);

        const outOfGamePlayTimeSegments = teamGamePlayerData.time_segments[this.currentPeriod].out_of_game;
        const outOfGameLastPlayTimeSegmentArray = outOfGamePlayTimeSegments[outOfGamePlayTimeSegments.length-1];
        const outOfGameCurrent = this.getTotalSegmentTime(outOfGameLastPlayTimeSegmentArray);

        let currentPeriodInGameTotal = 0;
        let currentPeriodOutOfGameTotal = 0;

        const totals = {
          in_game: 0,
          out_of_game: 0,
        };
        const periodTotals = {};

        for (let x = 0; x < this.numberOfPeriods; x++) {
          const period = x + 1;

          let inGameCurrent = 0;
          let outOfGameCurrent = 0;

          if (teamGamePlayerData.time_segments && teamGamePlayerData.time_segments[period]) {
            const inGamePlayTimeSegments = teamGamePlayerData.time_segments[period].in_game;
            // const inGameLastPlayTimeSegmentArray = inGamePlayTimeSegments[inGamePlayTimeSegments.length-1];
            // inGameCurrent = this.getTotalSegmentTime(inGameLastPlayTimeSegmentArray);

            const outOfGamePlayTimeSegments = teamGamePlayerData.time_segments[period].out_of_game;
            // const outOfGameLastPlayTimeSegmentArray = outOfGamePlayTimeSegments[outOfGamePlayTimeSegments.length-1];
            // outOfGameCurrent = this.getTotalSegmentTime(outOfGameLastPlayTimeSegmentArray);

            inGamePlayTimeSegments.forEach(inGamePlayTimeSegmentArray => {
              inGameCurrent += this.getTotalSegmentTime(inGamePlayTimeSegmentArray);
            });

            outOfGamePlayTimeSegments.forEach(outOfGamePlayTimeSegmentArray => {
              outOfGameCurrent += this.getTotalSegmentTime(outOfGamePlayTimeSegmentArray);
            });

          }

          periodTotals[period] = {
            'in_game': inGameCurrent,
            'out_of_game': outOfGameCurrent,
          };

          totals.in_game += inGameCurrent;
          totals.out_of_game += outOfGameCurrent;
        }

        inGamePlayTimeSegments.forEach(inGamePlayTimeSegmentArray => {
          currentPeriodInGameTotal += this.getTotalSegmentTime(inGamePlayTimeSegmentArray);
        });

        outOfGamePlayTimeSegments.forEach(outOfGamePlayTimeSegmentArray => {
          currentPeriodOutOfGameTotal += this.getTotalSegmentTime(outOfGamePlayTimeSegmentArray);
        });

        dataTableItem.current_period_total_time_in = currentPeriodInGameTotal;
        dataTableItem.current_period_total_time_out = currentPeriodOutOfGameTotal;
        dataTableItem.total_time_in_game = totals.in_game;
        dataTableItem.total_time_out_of_game = totals.out_of_game;
        dataTableItem.photo_url = teamPlayer.photo_url;

        this.timers.players[teamGamePlayerId] = {
          in_game: {
            current: `Current Stint: ${new Date(inGameCurrent).toISOString().substr(11, 8)}`,
            periodTotal: `${this.getPeriodLabel()} Total Time In: ${new Date(currentPeriodInGameTotal).toISOString().substr(11, 8)}`,
            gameTotal: `Game Total Time In: ${new Date(totals.in_game).toISOString().substr(11, 8)}`,
         },
          out_of_game: {
            current: `Time Since Last Stint: ${new Date(outOfGameCurrent).toISOString().substr(11, 8)}`,
            periodTotal: `${this.getPeriodLabel()} Total Time Out: ${new Date(currentPeriodOutOfGameTotal).toISOString().substr(11, 8)}`,
            gameTotal: `Game Total Time Out: ${new Date(totals.out_of_game).toISOString().substr(11, 8)}`,
          },
        };

        if (teamGamePlayerData.in_game) {
          dataTableItem.current_stint_time = inGameCurrent;
          dataTableItems.in_game.push(dataTableItem);
          if (teamGamePlayerData.max_time_per_stint) {
            const timeInMs = parseInt(teamGamePlayerData.max_time_per_stint) * 60000;
            if (inGameCurrent >= (timeInMs*0.75)) {
              dataTableItem.tired_class = 'tired-red';
            } else if ((timeInMs*0.75) >= inGameCurrent && inGameCurrent >= (timeInMs*0.5)) {
              dataTableItem.tired_class = 'tired-violet';
            } else if ((timeInMs*0.5) >= inGameCurrent && inGameCurrent >= (timeInMs*0.25)) {
              dataTableItem.tired_class = 'tired-orange';
            } else if ((timeInMs*0.25) >= inGameCurrent){
              dataTableItem.tired_class = 'tired-turquoise';
            }
          }
          
        } else {
          dataTableItem.time_out_since_last_stint = outOfGameCurrent;
          dataTableItems.out_of_game.push(dataTableItem);
          if (teamGamePlayerData.min_time_per_rest) {
            const timeInMs = parseInt(teamGamePlayerData.min_time_per_rest) * 60000;
            if (outOfGameCurrent >= (timeInMs*0.75)) {
              dataTableItem.tired_class = 'tired-turquoise';
            } else if ((timeInMs*0.75) >= outOfGameCurrent && outOfGameCurrent >= (timeInMs*0.5)) {
              dataTableItem.tired_class = 'tired-orange';
            } else if ((timeInMs*0.5) >= outOfGameCurrent && outOfGameCurrent >= (timeInMs*0.25)) {
              dataTableItem.tired_class = 'tired-violet';
            } else if ((timeInMs*0.25) >= outOfGameCurrent){
              dataTableItem.tired_class = 'tired-red';
            }
          }

        }
        
      }

      this.dataTableItems = dataTableItems;

      this.updating = false;
    },
    stopTimerInterval() {
      clearInterval(this.gamePlayerTimerInterval);
    },
    getPeriodLabel() {
      return `${TEAM.ordinal[this.currentPeriod]} ${TEAM.period.name[this.teamGame.type.toLowerCase()]}`;
    },
    addToGame() {
      if (!this.selectedOutOfGameDataTableItems.length) {
        alert('No players selected');
        return;
      }
      if (this.confirmOnChanges && !confirm(`Are you sure you want to add ${this.selectedOutOfGameDataTableItems.length > 1 ? 'these players' : 'this player'} to the game`)) {
        return;
      }

      this.$copy(this.selectedOutOfGameDataTableItems).forEach(item => {
        this.changeInGameState(item.team_game_player_id);
        this.updateClocks();
      });

      // Reset selected rows
      this.selectedOutOfGameDataTableItems = [];
    },
    removeFromGame() {
      if (!this.selectedInGameDataTableItems.length) {
        alert('No players selected');
        return;
      }
      if (this.confirmOnChanges && !confirm(`Are you sure you want to remove ${this.selectedInGameDataTableItems.length > 1 ? 'these players' : 'this player'} from the game`)) {
        return;
      }

      this.$copy(this.selectedInGameDataTableItems).forEach(item => {
        this.changeInGameState(item.team_game_player_id);
        this.updateClocks();
      });

      // Reset selected rows
      this.selectedInGameDataTableItems = [];
    },

    editTeamGamePlayer(teamGamePlayerId) {
      this.dialog = true;
      this.dialogTeamGamePlayerId = teamGamePlayerId;
      if (this.$dialog) {
        this.$dialog.show(TeamGamePlayerForm);
      }
    },
    closeDialog() {
      this.dialog = false
      this.dialogTeamGamePlayerId = '';
    },

  },
  watch: {
    timerIsStarted: {
      immediate: true,
      // eslint-disable-next-line
      handler(newVal, oldVal) {
        if (newVal) this.startTimerInterval();
      },
    },
    timerIsPaused: {
      immediate: true,
      // eslint-disable-next-line
      handler(newVal, oldVal) {
        if (newVal) this.stopTimerInterval();
      },
    },
    timerIsStopped: {
      immediate: true,
      // eslint-disable-next-line
      handler(newVal, oldVal) {
        if (newVal) this.stopTimerInterval();
      },
    },
  },
};
</script>
<template>
  <div class="container">
    <Breadcrumb :breadcrumb-items="$breadcrumbItems"></Breadcrumb>

    <div class="larger mb-5">{{ timers.period }}</div>
    <div v-if="this.teamGame.game_over" class="larger mb-5">{{ timers.total }}</div>

    <v-btn 
      v-if="timerIsStopped && !this.teamGame.game_over"
      @click="startGameTimer()"
      class="mb-5 mr-5"
      color="secondary"
    >
      Start {{ getPeriodLabel() }}
    </v-btn>
    <v-btn 
      v-if="timerIsPaused && !this.teamGame.game_over"
      @click="unPauseGameTimer()"
      class="mb-5 mr-5"
      color="secondary"
    >
      Un-pause {{ getPeriodLabel() }}
    </v-btn>
    <v-btn 
      v-if="timerIsStarted && !this.teamGame.game_over"
      @click="pauseGameTimer()"
      class="mb-5 mr-5"
      color="secondary"
    >
      Pause {{ getPeriodLabel() }}
    </v-btn>
    <v-btn 
      v-if="(timerIsStarted || timerIsPaused) && !this.teamGame.game_over"
      @click="stopGamePeriodTimer()"
      class="mb-5 white--text"
      color="red"
    >
      Stop {{ getPeriodLabel() }}
    </v-btn>

    <v-alert v-if="this.teamGame.game_over" border="left" class="white--text" color="error" dark>The game is over</v-alert>
    <v-alert border="left" class="white--text" color="success" dark>In the game</v-alert>

    <v-card
      v-if="!teamGamePlayersInGame || teamGamePlayersInGame.length === 0"
      class="mb-5 p-10"
    >You have no players in the game yet!</v-card>

    <v-data-table
      v-if="teamGamePlayersInGame && teamGamePlayersInGame.length"
      v-model="selectedInGameDataTableItems"
      :headers="dataTableHeaders.in_game"
      :items="dataTableItems.in_game"
      :items-per-page="100"
      sort-by="current_stint_time"
      hide-default-footer
      item-key="name"
      :show-select="!this.teamGame.game_over"
      class="elevation-1 mb-5"
    >
      <template
        v-if="!this.teamGame.game_over"
        v-slot:top
        >
        <v-btn
          @click="removeFromGame()"
          class="pa-3 m-5"
          color="error"
        >Remove from game</v-btn>
      </template>

      <template v-slot:item.name="{ item }">
        <div :class="item.tired_class">
          {{ item.name }}         
        </div>
      </template>

      <template v-slot:item.photo_url="{ item }">
        <div class="p-5">
          <v-img
            v-if="item.photo_url"
            :src="item.photo_url"
            max-height="50"
            max-width="50"
          />
        </div>
      </template>

      <template v-slot:item.current_stint_time="{ item }">
        {{ new Date(item.current_stint_time).toISOString().substr(11, 8) }}
      </template>
      <template v-slot:item.current_period_total_time_in="{ item }">
        {{ new Date(item.current_period_total_time_in).toISOString().substr(11, 8) }}
      </template>
      <template v-slot:item.current_period_total_time_out="{ item }">
        {{ new Date(item.current_period_total_time_out).toISOString().substr(11, 8) }}
      </template>
      <template v-slot:item.total_time_in_game="{ item }">
        {{ new Date(item.total_time_in_game).toISOString().substr(11, 8) }}
      </template>
      <template v-slot:item.total_time_out_of_game="{ item }">
        {{ new Date(item.total_time_out_of_game).toISOString().substr(11, 8) }}
      </template>

      <template
          v-if="!this.teamGame.game_over"
          v-slot:item.actions="{ item }"
        >
        <v-btn 
          x-small
          @click="editTeamGamePlayer(item.team_game_player_id)"
          class="warning"
        >Edit</v-btn>
      </template>

    </v-data-table>

    <v-alert border="left" class="white--text" color="indigo">Out of the game</v-alert>

    <v-card
      v-if="!teamGamePlayersOutOfGame || teamGamePlayersOutOfGame.length === 0"
      class="mb-5 p-10"
    >You have no players out of the game yet!</v-card>

    <v-data-table
      v-if="teamGamePlayersOutOfGame && teamGamePlayersOutOfGame.length"
      :headers="dataTableHeaders.out_of_game"
      :items="dataTableItems.out_of_game"
      :items-per-page="100"
      sort-by="time_out_since_last_stint"
      v-model="selectedOutOfGameDataTableItems"
      hide-default-footer
      item-key="name"
      :show-select="!this.teamGame.game_over"
      class="elevation-1 mb-5"
    >
      <template
        v-if="!this.teamGame.game_over"
        v-slot:top
        >
        <v-btn
          @click="addToGame()"
          class="pa-3 m-5"
          color="success"
        >Add to game</v-btn>
      </template>

      <template v-slot:item.photo_url="{ item }">
        <div class="p-5">
          <v-img
            v-if="item.photo_url"
            :src="item.photo_url"
            max-height="50"
            max-width="50"
          />
        </div>
      </template>

      <template v-slot:item.name="{ item }">
        <div :class="item.tired_class">
          {{ item.name }}         
        </div>
      </template>

      <template v-slot:item.time_out_since_last_stint="{ item }">
        {{ new Date(item.time_out_since_last_stint).toISOString().substr(11, 8) }}
      </template>
      <template v-slot:item.current_period_total_time_in="{ item }">
        {{ new Date(item.current_period_total_time_in).toISOString().substr(11, 8) }}
      </template>
      <template v-slot:item.current_period_total_time_out="{ item }">
        {{ new Date(item.current_period_total_time_out).toISOString().substr(11, 8) }}
      </template>
      <template v-slot:item.total_time_in_game="{ item }">
        {{ new Date(item.total_time_in_game).toISOString().substr(11, 8) }}
      </template>
      <template v-slot:item.total_time_out_of_game="{ item }">
        {{ new Date(item.total_time_out_of_game).toISOString().substr(11, 8) }}
      </template>

      <template
          v-if="!this.teamGame.game_over"
          v-slot:item.actions="{ item }"
        >
        <v-btn 
          x-small
          @click="editTeamGamePlayer(item.team_game_player_id)"
          class="warning"
        >Edit</v-btn>
      </template>

    </v-data-table>

    <!-- 
      TODO
      - initialize player with all players - and watch players and add player when player is added 
      - add extra info per subsitution like in/out, max, minimum rest duration
    -->

    <TeamGamePlayerForm
      :dialog="dialog"
      :team-game-player-id="dialogTeamGamePlayerId"
    ></TeamGamePlayerForm>


  </div>
</template>
<style lang="scss">
.larger {
  font-size: 30px;
}
.large {
  font-size: 20px;
}
.tired-orange {
  padding: 0px;
  border-bottom: 5px solid darkorange;
}
.tired-violet {
  padding: 0px;
  // border-bottom: 5px solid violet;
  border-bottom: 5px solid blueviolet;
}
.tired-turquoise {
  padding: 0px;
  border-bottom: 5px solid darkturquoise;
}
.tired-red {
  padding: 0px;
  border-bottom: 5px solid red;
}
</style>
