/*eslint no-console: ["error", { allow: ["warn", "error"] }] */
import Vue from 'vue';
import { INIT_DATA } from "cms-frontend/src/store/data";
import { INIT_DATA as MY_INIT_DATA } from "../data";
import Nprogress from 'nprogress';

import { saveState, syncState } from '../../helpers/store';

export default {
  namespaced: true,
  state: {
    data: INIT_DATA.copy(MY_INIT_DATA.team)
  },
  getters: {
    getAll: (state) => {
      syncState("team", state);
      return state.data.list.map(id => state.data.objects[id])
    },
    getById: (state) => id => { 
      syncState("team", state);
      return id ? state.data.objects[id] : {};
    },
  },
  actions: {
    add(context, data) {
      context.commit('add', data);
    },
    addList(context, dataList) {
      dataList.forEach(data => {
        context.commit('add', data);
      });
    },
    delete(context, teamId) {
      context.commit('delete', teamId);
    },
    // eslint-disable-next-line
    submitTeamAction(context, { method, payload, page }) {
      Nprogress.start();
      // return post(ServerUrls.get('feedback'), payload);
      context.commit("submitTeamMutationSuccess", {data: payload, page: page});
    },
    logoutUserAction(context) {
      context.commit("logoutUserMutation");
    },
  },
  mutations: {
    add(state, data) {
      Vue.set(state.data.objects, data.id, data);

      const objectIdArray = state.data.list;
      if (state.data.list.indexOf(data.id) < 0) {
        objectIdArray.push(data.id);
      }

      Vue.set(state.data, "list", objectIdArray);
    },
    delete(state, teamId) {

      const objects = INIT_DATA.copy(state.data.objects);
      let list = INIT_DATA.copy(state.data.list);

      // eslint-disable-next-line
      for (const [id, obj] of Object.entries(state.data.objects)) {
        if (teamId === id) {
          list = list.filter(idInList => idInList !== id);
          delete objects[id];
        }
      }

      Vue.set(
        state.data,
        "objects",
        objects
      );

      Vue.set(
        state.data,
        "list",
        list
      );
    },
    submitTeamMutationSuccess(state, { data, page }) {

      const stateData = INIT_DATA.copy(state.data);
      stateData.objects[data.id] = data;

      const objectIdArray = stateData.list;
      if (stateData.list.indexOf(data.id) < 0) {
        objectIdArray.push(data.id);
      }
      stateData.list = objectIdArray;

      saveState('team', state, stateData);

      Nprogress.done();
      Vue.notify({
        group: 'topCenterNotification',
        type: 'success',
        text: 'Team added',
      });

      page.closeDialog();
    },
    // eslint-disable-next-line
    submitTeamMutationFailure(state, error) {
      Nprogress.done();
      Vue.notify({
        group: 'topCenterNotification',
        type: 'error',
        text: 'Team failed to add'
      });
    },

    logoutUserMutation(state) {
      Vue.set(state, "data", INIT_DATA.copy(MY_INIT_DATA.team));
    },
  },
};
