<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

import TeamGameForm from './dialogs/TeamGameForm.vue';
import { EventBus } from '../../plugins/event-bus';
import TEAM from '../../constants/team';

export default {
  name: "TeamGames",
  components: { TeamGameForm },
  data() {
    return {
      dialog: false,
      dialogTeamId: 0,
      dialogTeamGameId: 0,
      TEAM: TEAM,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Type', value: 'type', sortable: false, },
        { text: 'Notes', value: 'notes', sortable: false, },
        { text: 'Number of players in a game', value: 'number_of_players' },
        { text: 'Time per period (minutes)', value: 'time_per_period' },
        { text: 'Maximum time per stint (minutes)', value: 'max_time_per_stint' },
        { text: 'Minimum rest time after play (minutes)', value: 'min_time_per_rest' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  beforeDestroy() {
    EventBus.$off(TEAM.events.closeTeamGameDialog);
  },
  created() {
    EventBus.$on(
      TEAM.events.closeTeamGameDialog,
      // eslint-disable-next-line
      arg => {
        this.closeDialog();
      },
    );

    // this.$getCoursesFromServer('administrator');
    // this.$getCourseUsersFromServer('administrator', this.courseId);
  },

  mounted() {},
  computed: {
    teamId() {
      return this.$route.params.team_id;
    },
    teamGameId() {
      return this.$route.params.team_game_id;
    },
    teamGames() {
      if (this.teamGameId) {
        const teamGames = this.$store.getters[
          'teamGame/getById'
        ](this.teamGameId);
        if (teamGames) {
          return [teamGames];
        }
      } else {
        const teamGames = this.$store.getters[
          'teamGame/getByTeamId'
        ](this.teamId);
        if (teamGames) {
          teamGames.sort((a, b) => (b.timestamp > a.timestamp ? 1 : -1));
          return teamGames;
        }
      }
      return [];
    }
  },
  methods: {
    
    goToDetail(id) {
      this.$router.push({
        name: "team_game_detail",
        params: {
          team_id: this.teamId,
          team_game_id: id
        }
      });
    },
    goToTeamGamePlay(teamId, teamGameId) {
      this.$router.push({
        name: "team_game_play",
        params: {
          team_id: teamId,
          team_game_id: teamGameId,
        }
      });
    },
    addTeamGame() {
      this.dialog = true;
      this.dialogTeamId = this.teamId;
      this.dialogTeamGameId = '';
      if (this.$dialog) {
        this.$dialog.show(TeamGameForm);
      }
    },
    editTeamGame(teamGameId) {
      this.dialog = true;
      this.dialogTeamId = this.teamId;
      this.dialogTeamGameId = teamGameId;
      if (this.$dialog) {
        this.$dialog.show(TeamGameForm);
      }
    },
    closeDialog() {
      this.dialogTeamId = '';
      this.dialogTeamGameId = '';
      this.dialog = false;
    },
  }
};
</script>
<template>
  <div class="container">
    <Breadcrumb :breadcrumb-items="$breadcrumbItems"></Breadcrumb>
    <v-btn
      v-if="!Boolean(teamGameId)"
      @click.stop="addTeamGame()"
      class="mb-5 mr-5"
      color="primary"
      >Add Game</v-btn
    >
    <v-card
      v-if="!teamGames || teamGames.length === 0"
      class="mb-5 p-10"
    >You have no team games yet!</v-card>

    <!-- div v-if="teamGames && teamGames.length" class="card-container">
      <v-card v-for="(teamGame) in teamGames" :key="teamGame.id" class="mb-5">
        <v-card-title class="hover">{{teamGame.name}}</v-card-title>        
        <v-card-text>{{teamGame.type}} | {{teamGame.number_of_players}} v {{teamGame.number_of_players}} for {{teamGame.time_per_period}} minutes per {{TEAM.period.name[teamGame.type.toLowerCase()].toLowerCase()}}</v-card-text>

        <v-card-actions v-if="(type = 'team')">
          <v-btn 
            :disabled="Boolean(teamGameId)"
            class="white--text"
            @click="goToDetail(teamGame.id)" color="grey"
            >Detail</v-btn
          >
          <v-btn @click="goToTeamGamePlay(teamId, teamGame.id)" :color="teamGame.game_over ? '' : 'primary'"
            >{{ teamGame.game_over ? 'Stats' : 'Play' }}</v-btn
          >
        </v-card-actions>

      </v-card>
    </div -->

    <v-data-table
      v-if="teamGames && teamGames.length"
      :headers="headers"
      :items="teamGames"
      :items-per-page="100"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
        <v-btn 
          v-if="!item.game_started && !item.game_over"
          x-small
          @click="editTeamGame(item.id)"
          class="warning mr-2"
        >Edit</v-btn>
        <v-btn 
          x-small @click="goToTeamGamePlay(item.team_id, item.id)" :color="item.game_over ? '' : 'primary'"
        >{{ item.game_over ? 'Stats' : 'Play' }}</v-btn>

      </template>
    </v-data-table>

    <TeamGameForm
      :dialog="dialog"
      :team-id="dialogTeamId"
      :team-game-id="dialogTeamGameId"
    ></TeamGameForm>

  </div>
</template>
