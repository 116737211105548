import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=323adc9c&scoped=true&"
var script = {}
import style0 from "@/assets/css/bootstrap..min.css?vue&type=style&index=0&id=323adc9c&scoped=true&lang=css&"
import style1 from "@/assets/css/boxicons.min.css?vue&type=style&index=1&id=323adc9c&scoped=true&lang=css&"
import style2 from "@/assets/css/flaticon.css?vue&type=style&index=2&id=323adc9c&scoped=true&lang=css&"
import style3 from "@/assets/css/meanmenu.min.css?vue&type=style&index=3&id=323adc9c&scoped=true&lang=css&"
import style4 from "@/assets/css/animate.min.css?vue&type=style&index=4&id=323adc9c&scoped=true&lang=css&"
import style5 from "@/assets/css/owl.carousel.min.css?vue&type=style&index=5&id=323adc9c&scoped=true&lang=css&"
import style6 from "@/assets/css/odometer.min.css?vue&type=style&index=6&id=323adc9c&scoped=true&lang=css&"
import style7 from "@/assets/css/nice-select.min.css?vue&type=style&index=7&id=323adc9c&scoped=true&lang=css&"
import style8 from "@/assets/css/viewer.min.css?vue&type=style&index=8&id=323adc9c&scoped=true&lang=css&"
import style9 from "@/assets/css/slick.min.css?vue&type=style&index=9&id=323adc9c&scoped=true&lang=css&"
import style10 from "@/assets/css/magnific-popup.min.css?vue&type=style&index=10&id=323adc9c&scoped=true&lang=css&"
import style11 from "@/assets/css/style.css?vue&type=style&index=11&id=323adc9c&scoped=true&lang=css&"
import style12 from "@/assets/css/responsive.css?vue&type=style&index=12&id=323adc9c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "323adc9c",
  null
  
)

export default component.exports