<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */
import TeamForm from './dialogs/TeamForm.vue';
import UploadDownloadDeleteDataForm from './dialogs/UploadDownloadDeleteDataForm.vue';
import { EventBus } from '../../plugins/event-bus';
import TEAM from '../../constants/team';

export default {
  name: 'Teams',
  components: { TeamForm, UploadDownloadDeleteDataForm },
  data() {
    return {
      dialog: false,
      dialogData: false,
      dialogTeamId: 0,
      headers: [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Description', value: 'description', sortable: false },
        { text: 'Confirm before changing?', sortable: false, value: 'confirm_on_changes' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

    };
  },
  beforeDestroy() {
    EventBus.$off(TEAM.events.closeTeamDialog);
    EventBus.$off(TEAM.events.closeUploadDownloadDeleteDataDialog);
    EventBus.$off(TEAM.events.closeUploadDownloadDeleteDataAndReloadDialog);
  },
  created() {
    EventBus.$on(
      TEAM.events.closeTeamDialog,
      // eslint-disable-next-line
      arg => {
        this.closeDialog();
      },
    );
    EventBus.$on(
      TEAM.events.closeUploadDownloadDeleteDataDialog,
      // eslint-disable-next-line
      arg => {
        this.closeDataDialog();
      },
    );
    EventBus.$on(
      TEAM.events.closeUploadDownloadDeleteDataAndReloadDialog,
      // eslint-disable-next-line
      arg => {
        this.closeDataDialogAndReload();
      },
    );
    // this.$getCoursesFromServer('administrator');
    // this.$getCourseUsersFromServer('administrator', this.courseId);
  },
  computed: {
    teamId() {
      return this.$route.params.team_id;
    },
    teams() {
      if (this.teamId) {
        return [
          this.$store.getters['team/getById'](this.teamId),
        ];
      } else {
        const teams = this.$store.getters['team/getAll'];
        if (teams) {
          teams.sort((a, b) => (b.timestamp > a.timestamp ? 1 : -1));
          return teams;
        }

        return [];
      }
    },
  },
  methods: {
    goToDetail(id) {
      this.$router.push({
        name: 'team_detail',
        params: { team_id: id },
      });
    },
    goToTeamGame(teamId) {
      this.$router.push({
        name: 'team_games',
        params: { team_id: teamId },
      });
    },
    goToTeamPlayer(teamId) {
      this.$router.push({
        name: 'team_players',
        params: { team_id: teamId },
      });
    },
    uploadDownloadDeleteData() {
      this.dialogData = true;
      if (this.$dialog) {
        this.$dialog.show(UploadDownloadDeleteDataForm);
      }
    },
    addTeam() {
      this.dialog = true;
      this.dialogTeamId = '';
      if (this.$dialog) {
        this.$dialog.show(TeamForm);
      }
    },
    editTeam(teamId) {
      this.dialog = true;
      this.dialogTeamId = teamId;
      if (this.$dialog) {
        this.$dialog.show(TeamForm);
      }
    },
    closeDialog() {
      this.dialogTeamId = '';
      this.dialog = false;
    },
    closeDataDialog() {
      this.dialogData = false;
    },
    closeDataDialogAndReload() {
      this.closeDataDialog();
      window.location.reload();
    },
  },
};
</script>
<template>
  <div class="container">
    <Breadcrumb :breadcrumb-items="$breadcrumbItems"></Breadcrumb>
    <v-btn
      v-if="!Boolean(teamId)"
      @click.stop="addTeam()"
      class="mb-5 mr-5"
      color="primary"
      >Add Team</v-btn
    >
    <!-- v-btn
      v-if="!Boolean(teamId)"
      @click.stop="deleteAllData()"
      class="mb-5 white--text"
      color="red"
      >Delete all data</v-btn
    -->
    <v-btn
      v-if="!Boolean(teamId)"
      @click.stop="uploadDownloadDeleteData()"
      class="mb-5 white--text"
      color="red"
      >Upload / Download / Delete</v-btn
    >
    <v-card
      v-if="!teams || teams.length === 0"
      class="mb-5 p-10"
      >You have no teams yet!</v-card
    >

    <v-data-table
      v-if="teams && teams.length"
      :headers="headers"
      :items="teams"
      :items-per-page="100"
      class="elevation-1"
    >
      <template v-slot:item.actions="{ item }">
      </template>
      <template v-slot:item.confirm_on_changes="{ item }">
        <v-checkbox
          disabled
          v-model="item.confirm_on_changes"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon -->
        <v-btn 
          x-small
          color="warning"
          @click="editTeam(item.id)"
          class="mr-2"
        >Edit</v-btn>

        <v-btn
          x-small
          @click="goToTeamPlayer(item.id)"
          color="primary"
          class="mr-2"
        >Players</v-btn>
        <v-btn
          x-small
          @click="goToTeamGame(item.id)"
          color="primary"
        >Games</v-btn>

      </template>
    </v-data-table>

    <TeamForm
      :dialog="dialog"
      :team-id="dialogTeamId"
    ></TeamForm>
    <UploadDownloadDeleteDataForm
      :dialog="dialogData"
    />

  </div>
</template>