<script>
/*eslint no-console: ["error", { allow: ["warn", "error"] }] */

import TeamPlayerForm from './dialogs/TeamPlayerForm.vue';
import { EventBus } from '../../plugins/event-bus';
import TEAM from '../../constants/team';

export default {
  name: "TeamPlayers",
  components: { TeamPlayerForm },
  data() {
    return {
      dialog: false,
      dialogTeamId: 0,
      dialogTeamPlayerId: 0,
    };
  },
  beforeDestroy() {
    EventBus.$off(TEAM.events.closeTeamPlayerDialog);
  },
  created() {
    EventBus.$on(
      TEAM.events.closeTeamPlayerDialog,
      // eslint-disable-next-line
      arg => {
        this.closeDialog();
      },
    );

    // this.$getCoursesFromServer('administrator');
    // this.$getCourseUsersFromServer('administrator', this.courseId);
  },

  computed: {
    hasPhotos() {
      return this.teamPlayers.filter(item => item.photo_url).length > 0;
    },
    hasNotes() {
      return this.teamPlayers.filter(item => item.notes).length > 0;
    },
    headers() {
      const headers = [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
      ];

      if (this.hasPhotos) {
        headers.push(
          {
            text: 'Photo',
            value: 'photo_url', 
            sortable: false
          },
        );
      }
      if (this.hasNotes) {
        headers.push(
          {
            text: 'Notes',
            value: 'notes', 
            sortable: false
          },
        );
      }

      if (!this.$isMobile) {
        headers.push(
          {
            text: 'Maximum time per stint (minutes)',
            value: 'max_time_per_stint'
          }
        );
        headers.push(
          {
            text: 'Minimum rest time after play (minutes)',
            value: 'min_time_per_rest'
          }
        );
      }

      headers.push(
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      );

      return headers;

    },
    teamId() {
      return this.$route.params.team_id;
    },
    teamPlayerId() {
      return this.$route.params.team_player_id;
    },
    teamPlayers() {
      if (this.teamPlayerId) {
        const teamPlayers = this.$store.getters[
          'teamPlayer/getById'
        ](this.teamPlayerId);
        if (teamPlayers) {
          return [teamPlayers];
        }
      } else {
        const teamPlayers = this.$store.getters[
          'teamPlayer/getByTeamId'
        ](this.teamId);
        if (teamPlayers) {
          teamPlayers.sort((a, b) => (b.timestamp > a.timestamp ? 1 : -1));
          return teamPlayers;
        }
      }
      return [];
    }
  },
  methods: {
    /*
    goToDetail(id) {
      this.$router.push({
        name: "team_player_detail",
        params: {
          team_id: this.$route.params.team_id,
          team_player_id: id
        }
      });
    },
    goToTeamPlayerResponses(id) {
      this.$router.push({
        name: "team_player_user_responses",
        params: {
          team_id: this.$route.params.team_id,
          team_player_id: id
        }
      });
    }
    */
    addTeamPlayer() {
      this.dialog = true;
      this.dialogTeamId = this.teamId;
      this.dialogTeamPlayerId = '';
      if (this.$dialog) {
        this.$dialog.show(TeamPlayerForm);
      }
    },
    editTeamPlayer(teamPlayerId) {
      this.dialog = true;
      this.dialogTeamId = this.teamId;
      this.dialogTeamPlayerId = teamPlayerId;
      if (this.$dialog) {
        this.$dialog.show(TeamPlayerForm);
      }
    },
    closeDialog() {
      this.dialogTeamId = '';
      this.dialogTeamPlayerId = '';
      this.dialog = false;
    },

  }
};
</script>
<template>
  <div class="container">
    <Breadcrumb :breadcrumb-items="$breadcrumbItems"></Breadcrumb>
    <v-btn
      v-if="!Boolean(teamPlayerId)"
      @click.stop="addTeamPlayer()"
      class="mb-5 mr-5"
      color="primary"
      >Add Player</v-btn
    >
    <v-card
      v-if="!teamPlayers || teamPlayers.length === 0"
      class="mb-5 p-10"
    >You have no team players yet!</v-card>

    <v-data-table
      v-if="teamPlayers && teamPlayers.length"
      :headers="headers"
      :items="teamPlayers"
      :items-per-page="100"
      class="elevation-1"
    >
      <template v-slot:item.photo_url="{ item }">
        <div class="p-5">
          <v-img
            v-if="item.photo_url"
            :src="item.photo_url"
            max-height="50"
            max-width="50"
          />
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- v-icon
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon -->
        <v-btn 
          x-small
          @click="editTeamPlayer(item.id)"
          class="warning"
        >Edit</v-btn>

      </template>
    </v-data-table>

    <TeamPlayerForm
      :dialog="dialog"
      :team-id="dialogTeamId"
      :team-player-id="dialogTeamPlayerId"
    ></TeamPlayerForm>

  </div>
</template>